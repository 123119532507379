import React, {useEffect, useRef, useState} from 'react';
import {Modal} from '@/components/common-components/components';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Checkbox, Dropdown, Menu, Spin, Tooltip} from 'antd';
import {faTrash, faListTree, faArrowUpRightFromSquare, faChevronRight, faArrowRight} from '@fortawesome/pro-regular-svg-icons';
import {useStore} from '@/store/root-store';
import {getSingleUrlParam} from '@/utils/url';
import {openUrl} from '@/utils/router';
import {faArrowUpFromLine, faChevronDown, faChevronUp, faCircleCheck, faFileLines, faWandMagicSparkles} from '@fortawesome/pro-solid-svg-icons';
import {faCircleCheck as faDuotoneCircleCheck, faCirclePlus} from '@fortawesome/pro-duotone-svg-icons';
import {LoadingOutlined} from '@ant-design/icons';
import {PAGES_API} from '@/api/content-optimizer';
import {notification as notificationV2} from '@/utils/notification-v2';
import {apiError} from '@/utils/api';
import {PRO_PLAN_ID} from '@/constants';
import {OneClickPublisher} from '../../../../landing-page-optimizer/page-editor/LeftSide/TopSection/title-editor/OneClickPublisher/OneClickPublisher';
import {convertToTransparent} from '@/utils/colors';
import {topicalMapStepsTitles} from '../../../Constants';
import {BackButton, DeleteButton, GenerateArticleMenu, ModalWrapper, PluginButton, StyledEmpty, TopicalMapStepsWrapper, Wrapper} from '../../../style';
import {StyledSpin} from '../../../Utils/styledSpin';
import {GenerateButton} from '..';
import FreezeWrapper from '../../freezTooltip';
import {observer} from 'mobx-react-lite';
import {useRouter} from 'next/router';
import {isNodeSelected} from '../../../Utils/helper-func';
import {flattenChildren} from './topicalMapModalContent';

interface TopicalMapModalTableContentProps {
  keysWithChildren: any;
  topicMapDataList: any;
  generatingBulkArticles: any;
  setGeneratingBulkArticles: any;
  selectedArticlesToGenerate: any;
  setSelectedArticlesToGenerate: any;
  setTopicalMapArticle: any;
  topicalMapArticle: any;
  setDataLoader: any;
  topicalMap: any;
  ExportButton: any;
  searchIntentDropdown: any;
  openTopicalMapModal?: boolean;
  setOpenTopicalMapModal?: (value: boolean) => void;
  componentIssueType: string;
  isInContentTopicMap?: boolean;
  tableDataFilter: any;
  categoryIntentDropdown: any;
  searchInput: any;
  func: any;
  topicalMapDataState: any;
  setKeysWithChildren: any;
  searchIntentListLength?: number;
}

const defaultCounty = {
  countryCode: 'US',
  location: 'United States',
  locationId: 2840,
};

export const TopicalMapModalTableContent: React.FC<TopicalMapModalTableContentProps> = observer((
  {
    keysWithChildren,
    topicMapDataList,
    generatingBulkArticles,
    setGeneratingBulkArticles,
    selectedArticlesToGenerate,
    setSelectedArticlesToGenerate,
    setTopicalMapArticle,
    topicalMapArticle,
    setDataLoader,
    topicalMap,
    componentIssueType,
    isInContentTopicMap,
    tableDataFilter,
    ExportButton,
    searchIntentDropdown,
    categoryIntentDropdown,
    searchInput,
    func,
    topicalMapDataState,
    setKeysWithChildren,
    searchIntentListLength,
  }) => {
  const {ottoV2Store: {loadIssueTableData, ottoUrls, deletingTopicalMap, topicalMapUpdateArticle, createTopicalMapBulkArticles, getOttoV2Project},
    settings: {customer: {profile: {address, plan}, getCustomerQuota}},
    contentOptimizer: {
      pages: {incrementInTotalArticle},
      aiOutline: {setTopUpBulkVisibility},
      wpPublish: {wpArray, setShowInstructionsModal},
      contentIdeas: {getTopicalMapData, loadSingleTopicMapData},
    },
  } = useStore('');
  const router = useRouter();

  const [openModal, setOpenModal] = useState(false);
  const [deleteId, setDeleteId] = useState<any>('');
  const [deleteModalData, setDeleteModalData] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(defaultCounty);
  const [errorId, setErrorId] = useState([]);
  const [clickedValue, setClickedValue] = useState<any>(0);
  const wpContainerRef = useRef(null);
  const [showClass, setShowClass] = useState(false);
  const tableRef = useRef(null);

  const uuid = getSingleUrlParam('uuid');
  const generateColor = si => {
    if (si === 'informational') {
      return '#34AEF3';
    } else if (si === 'commercial') {
      return '#F1AA3E';
    } else if (si === 'transactional') {
      return '#1F9068';
    } else if (si === 'navigational') {
      return '#B98CE4';
    } else {
      return '#474545';
    }
  };

  const updateScrollClass = () => {
    const width = window.innerWidth;
    const scrollLeft = tableRef.current.scrollLeft;
    const scrollWidth = tableRef.current.scrollWidth;
    const clientWidth = tableRef.current.clientWidth;

    const isCompletelyScrolled = scrollLeft + clientWidth >= scrollWidth;

    if (width < 1872 && !isCompletelyScrolled) {
      setShowClass(true);
    } else if (width < 1872 && isCompletelyScrolled) {
      setShowClass(false);
    } else {
      setShowClass(false);
    }
  };

  useEffect(() => {
    updateScrollClass();

    window.addEventListener('resize', updateScrollClass);
    tableRef.current.addEventListener('scroll', updateScrollClass);

    return () => {
      window.removeEventListener('resize', updateScrollClass);
      tableRef.current && tableRef.current.removeEventListener('scroll', updateScrollClass);
    };
  }, [tableRef]);

  const scrollToEnd = () => {
    if (tableRef.current) {
      tableRef.current.scrollTo({
        left: tableRef.current.scrollWidth,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    if (address?.defaultLocation) {
      setSelectedCountry({
        countryCode: address?.defaultLocation,
        location: address?.countryName,
        locationId: address?.locationId,
      });
    }
  }, [address?.defaultLocation]);

  useEffect(() => {
    if (clickedValue) {
      const updatedData = isNodeSelected(clickedValue, {...topicalMapDataState});
      func(updatedData);
      setClickedValue(0);
    }
  }, [clickedValue]);

  const updateTopicalMapArticle = async articleData => {
    const filter = ottoUrls?.filter(d => d.issueType === componentIssueType);
    const payload = {
      id: articleData?.id,
      titleUuid: articleData?.titleUuid,
      pageUuid: articleData?.pageUuid,
    };
    await topicalMapUpdateArticle(payload);
    const params = {
      uuid,
      issue_type: componentIssueType,
      page_size: filter?.length ? filter[0].pageSize : 10,
      page: filter?.length ? filter[0].page : 1,
      is_loading: false,
    };
    if (isInContentTopicMap) {
      await loadSingleTopicMapData(Number(router.query['topicId']), true);
    } else {
      await loadIssueTableData(params, false, true);
    }
  };

  const groupById = data => {
    const result = {};
    data.forEach(item => {
      if (!result[item.id]) {
        result[item.id] = [];
      }
      result[item.id].push(item.titleUuid);
    });
    return result;
  };

  const deleteArticle = async (contentTopic, topicalMapArticleDataParam) => {
    setOpenModal(false);
    try {
      const selectedArticle = topicalMapArticleDataParam?.find(i => i?.title === contentTopic);
      setDeleteId(selectedArticle?.titleUuid);
      const filter = ottoUrls?.filter(d => d.issueType === componentIssueType);
      const payload = {
        id: selectedArticle?.id,
        titleUuid: selectedArticle?.titleUuid,
        shouldDelete: true,
        publicShareHash: getOttoV2Project?.publicShareHash,
      };
      await topicalMapUpdateArticle(payload);
      setDataLoader(true);
      const params = {
        uuid,
        issue_type: componentIssueType,
        page_size: filter?.length ? filter[0].pageSize : 10,
        page: filter?.length ? filter[0].page : 1,
        is_loading: false,
      };
      if (isInContentTopicMap) {
        await loadSingleTopicMapData(Number(router.query['topicId']), true);
        await getTopicalMapData();
      } else {
        await loadIssueTableData(params, false, true);
      }
      notificationV2.success('', 'Article deleted successfully', 'Close');
      setDeleteId('');
      setOpenModal(false);
      setDeleteModalData(null);
    } catch (e) {
      setDeleteId('');
      setOpenModal(false);
      setDeleteModalData(null);
      const errorMessage = apiError(e);
      notificationV2.error('', errorMessage);
    }
  };

  const onGenerateArticle = async (articles: any[], singleArticle?: boolean) => {
    setGeneratingBulkArticles(true);
    try {
      const articleGroup = groupById(articles);
      const filter = ottoUrls?.filter(d => d.issueType === componentIssueType);
      for (let index = 0; index < Object.keys(articleGroup).length; index++) {
        const key = Object.keys(articleGroup)[index];
        const uuids = articleGroup[key];
        const payload = {
          'uuids': uuids,
        };
        await createTopicalMapBulkArticles(key, payload, singleArticle);
      }
      setDataLoader(true);
      const params = {
        uuid,
        issue_type: componentIssueType,
        page_size: filter?.length ? filter[0].pageSize : 10,
        page: filter?.length ? filter[0].page : 1,
        is_loading: false,
      };
      setDeleteModalData(null);
      if (isInContentTopicMap) {
        await loadSingleTopicMapData(Number(router.query['topicId']), true);
      } else {
        await loadIssueTableData(params, false, true);
      }
      await getCustomerQuota();
      incrementInTotalArticle();
      notificationV2.success('Success', `Article${singleArticle ? '' : 's'} created successfully.`);
    } catch (e) {
      setGeneratingBulkArticles(false);
      if (e?.response?.status && e?.response?.status === 429) {
        plan === PRO_PLAN_ID && setTopUpBulkVisibility(true);
      } else if (e?.response?.status && e?.response?.status === 406) {
        notificationV2.error(`Could not generate ${singleArticle ? 'article' : 'bulk articles'}`, e?.response?.data?.keywordsList, false);
      } else {
        const errorMessage = apiError(e);
        notificationV2.error('', errorMessage);
      }
    }
  };

  const onOutline = async (child: any) => {
    const alreadyExist = topicalMapArticle.some(i => i?.titleUuid === child?.titleUuid);
    if (!alreadyExist) {
      const newArticle = {
        id: child?.id,
        titleUuid: child?.titleUuid,
        title: child?.name,
        isLoading: true,
        pageUuid: '',
      };
      setTopicalMapArticle(prev => [...prev, newArticle]);
    } else {
      setTopicalMapArticle(prev => prev.map(i => {
        if (i?.title === child?.name) {
          return {...i, isLoading: true};
        }
        return i;
      }));
    }
    const payload = {
      ...{
        'should_generate_outline': true,
        'keywords_list': [child?.name],
        'location': 'United States',
        'location_id': 2840,
      },
      ...(selectedCountry?.location && {location: selectedCountry?.location}),
      ...(selectedCountry?.locationId && {location_id: selectedCountry?.locationId}),
    };

    const topicalMapArticleDataParam = [...topicalMapArticle, {
      id: child?.id,
      titleUuid: child?.titleUuid,
      title: child?.name,
      isLoading: true,
      pageUuid: '',
    }];
    try {
      const res = await PAGES_API.generateOutlineWithoutContent(payload);
      setTimeout( async ()=> {
        if (res?.uuid) {
          getCustomerQuota();
          incrementInTotalArticle();
          const selectedArticle = topicalMapArticleDataParam?.find(i => i?.titleUuid === child?.titleUuid);
          const articleData = {...selectedArticle, pageUuid: res?.uuid};
          if (articleData?.pageUuid) {
            await updateTopicalMapArticle(articleData);
            setTopicalMapArticle(prev => {
              const updatedData = prev.filter(i => {
                if (i?.titleUuid === child?.titleUuid) {
                  return false;
                }
                return true;
              });
              return updatedData;
            });
            setDataLoader(true);
            notificationV2.success('Success', 'Outline generated successfully.');
          }
        }
      }, 1000);
    } catch (e) {
      setTopicalMapArticle(prev => {
        const updatedData = prev.filter(i => {
          if (i?.titleUuid === child?.titleUuid) {
            return false;
          }
          return true;
        });
        return updatedData;
      });
      if (e?.response?.status && e?.response?.status === 429) {
        plan === PRO_PLAN_ID && setTopUpBulkVisibility(true);
      } else if (e?.response?.status && e?.response?.status === 406) {
        notificationV2.error('Could not generate Outline', e?.response?.data?.keywordsList, false);
      } else {
        const errorMessage = apiError(e);
        notificationV2.error('', errorMessage);
      }
    }
  };

  const onChangeActive = (checked: boolean, value: string) => {
    let updateList = [...keysWithChildren];
    if (checked) {
      updateList.push(value);
    } else {
      updateList = updateList.filter(key => key !== value);
    }
    setKeysWithChildren(updateList);
  };
  const getSILength = (length: number, usingFor: string) => {
    if (usingFor === 'data') {
      if (length === 4) {
        return '114px';
      } else if (length === 3) {
        return '84px';
      } else if (length === 2) {
        return '84px';
      } else if (length === 1) {
        return '44px';
      } else {
        return '44px';
      }
    } else {
      if (length === 4) {
        return '116px';
      } else if (length === 3) {
        return '86px';
      } else if (length === 2) {
        return '86px';
      } else if (length === 1) {
        return '48px';
      } else {
        return '48px';
      }
    }
  };
  const getTableBodyWidth = (intentLength: number) => {
    if (intentLength === 4) {
      return '1746px';
    } else if (intentLength === 3) {
      return '1726px';
    } else if (intentLength === 2) {
      return '1726px';
    } else if (intentLength === 1) {
      return '1726px';
    } else {
      return '1726px';
    }
  };

  const getDeleteWidth = (intentLength: number) => {
    if (intentLength === 4) {
      return '114px';
    } else if (intentLength === 3) {
      return '54px';
    } else if (intentLength === 2) {
      return '60px';
    } else if (intentLength === 1) {
      return '94px';
    } else {
      return '94px';
    }
  };
  const getActionHeaderWidth = (intentLength: number) => {
    if (intentLength === 4) {
      return '116px';
    } else if (intentLength === 3) {
      return '536px';
    } else if (intentLength === 2) {
      return '536px';
    } else if (intentLength === 1) {
      return '574px';
    } else {
      return '574px';
    }
  };

  const IdeaRow = ({child}) => {
    const hasLength = !!topicalMapArticle?.filter(i => i?.titleUuid === child?.titleUuid)?.length;
    const isLoading = topicalMapArticle?.find(i => i?.titleUuid === child?.titleUuid)?.isLoading;
    const isGenerating = (hasLength && isLoading) || (!child?.pageUuid && child?.aiGenerationStatus === 'PENDING') || (selectedArticlesToGenerate?.filter(article => article?.titleUuid === child?.titleUuid)?.length && generatingBulkArticles);
    return (
      <div key={child?.name || Math.random()} style={{width: '100%', display: 'flex', boxShadow: '0px 1px 0px 0px #E7E7E7', alignItems: 'flex-start', padding: '4px 0px'}}>
        <CheckboxWrapper style={{minWidth: '94px', maxWidth: '94px'}}>
          {isInContentTopicMap ? <>
            {child?.isSelected ? <FontAwesomeIcon icon={faDuotoneCircleCheck} color='#1FAC47' fontSize={16}/> : <FontAwesomeIcon icon={faCirclePlus} color='#A3A4A4' fontSize={16} />}
          </> : <TableCheckbox defaultChecked={child?.isSelected} onClick={() => setClickedValue(child?.value)} />}
        </CheckboxWrapper>
        <div style={{minWidth: getSILength(searchIntentListLength, 'data'), maxWidth: getSILength(searchIntentListLength, 'data'), display: 'flex', alignItems: 'center', gap: '8px'}}>
          {child?.searchIntent?.length ?
            child?.searchIntent?.map((si, index) => (
              <StyledTag key={index} style={{color: generateColor(si?.toLowerCase()), backgroundColor: convertToTransparent(generateColor(si?.toLowerCase()), '10%')}}>
                {si?.toLowerCase() === 'informational' ? 'I' : si?.toLowerCase() === 'commercial' ? 'C' : si?.toLowerCase() === 'transactional' ? 'T' : si?.toLowerCase() === 'navigational' ? 'N' : 'NA'}
              </StyledTag>
            )):
            null}
        </div>
        <Keyword style={{minWidth: '290px', maxWidth: '290px'}}>{child?.name}</Keyword>
        <Title style={{minWidth: '470px', maxWidth: '470px', fontWeight: 400}}>{child?.titleName}</Title>
        <ActionsWrapper style={{display: 'flex', alignItems: 'center', gap: '14px', minWidth: '476px', maxWidth: '476px'}}>
          <TopicalMapStepsWrapper style={{marginTop: '0px'}}>
            <div className='steps' style={{maxHeight: '34px'}}>
              {topicalMapStepsTitles.map((title, index) => (
                <div
                  key={index}
                  className={`section ${
                    (index === 0) ? 'active-section ' :
                      (index === 1 && !isLoading && child?.pageUuid) ? 'active-section ' :
                        (index === 2 && !isLoading && child?.wpPublishedAtUrl) ? 'active-section ' : ''
                  } ${(index !== 0) ? '' : ' restricted'} ${!topicalMap?.project && 'disabled-btn'}`}
                  onClick={() => {}}
                >
                  <div className={`step ${(index === 0 && !isLoading && child?.pageUuid) ? 'active' : ''}`}>
                    {index === 0 ?
                      (hasLength && isLoading) || (!child?.pageUuid && child?.aiGenerationStatus === 'PENDING') || (selectedArticlesToGenerate?.filter(article => article?.titleUuid === child?.titleUuid)?.length && generatingBulkArticles) ?
                        <FreezeWrapper>
                          <ViewButton style={{color: '#7F4EAD', backgroundColor: 'rgba(127, 78, 173, 0.15)', width: '162px', padding: '3px 12px'}}>
                            <Spin indicator={<LoadingOutlined style={{fontSize: 14, color: '#7F4EAD'}} spin />} />Generating...
                          </ViewButton>
                        </FreezeWrapper> :
                        child?.pageUuid ? <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                          <FontAwesomeIcon icon={faCircleCheck} color='#7F4EAD' fontSize={14} />
                          <GenerateButton style={{color: '#4E5156', width: '84px'}} onClick={() => openUrl(`/content/seo-content-assistant/${child?.pageUuid}`, '_blank')}>View content</GenerateButton>
                        </div> : <FreezeWrapper><Dropdown trigger={['click']}
                          disabled={!topicalMap?.project}
                          overlayStyle={{top: '974px', left: '972px'}}
                          overlay={<GenerateArticleMenu>
                            <Menu.Item className='menu-item'
                              onClick={() => {
                                setSelectedArticlesToGenerate([{id: child?.id, titleUuid: child?.titleUuid}]);
                                onGenerateArticle([{id: child?.id, titleUuid: child?.titleUuid}], true);
                              }}>
                              <FontAwesomeIcon icon={faFileLines} color='#121212' fontSize={14}/>Full Article with AI
                            </Menu.Item>
                            <Menu.Item className='menu-item'
                              onClick={() => {
                                setSelectedArticlesToGenerate([{id: child?.id, titleUuid: child?.titleUuid}]);
                                onOutline(child);
                              }}><FontAwesomeIcon icon={faListTree} color='#121212' fontSize={14}/>Headings Outline with AI</Menu.Item>
                          </GenerateArticleMenu>}>
                          <Tooltip title={!topicalMap?.project && 'No project folder attached to this topical map'} overlayInnerStyle={{borderRadius: '10px'}}>
                            <ViewButton
                              style={{
                                cursor: !topicalMap?.project ? 'not-allowed' : 'pointer',
                                color: !topicalMap?.project ? '#666666' : '#7F4EAD',
                                backgroundColor: !topicalMap?.project ? '#cccccc' : 'rgba(127, 78, 173, 0.15)', width: '162px',
                                padding: '5px 12px',
                              }}>
                              <FontAwesomeIcon icon={faWandMagicSparkles} color={!topicalMap?.project ? '#666666' : '#7F4EAD'} fontSize={14} />{title}
                            </ViewButton>
                          </Tooltip>
                        </Dropdown> </FreezeWrapper>: index === 1 ? (<FreezeWrapper>
                        {!isLoading && child?.pageUuid && child?.wpPublishedAtUrl ? (
                          <OneClickPublisher getPopupContainer={() => wpContainerRef?.current || document.body} errorId={errorId} setErrorId={setErrorId} topicalMapName={child?.wpPublishedAtUrl} titleUuid={child?.titleUuid} uuid={child?.pageUuid} isInContentTopicMap={isInContentTopicMap} setDataLoader={setDataLoader}/>
                        ) : (
                          <>
                            {
                              !isLoading && child?.pageUuid ?
                                !wpArray?.length ? (
                                  <PluginButton onClick={() => setShowInstructionsModal(true)}><FontAwesomeIcon icon={faArrowUpFromLine} color={'#7F4EAD'} fontSize={14} />{title}</PluginButton>
                                ) : (
                                  <OneClickPublisher getPopupContainer={() => wpContainerRef?.current || document.body} errorId={errorId} setErrorId={setErrorId} topicalMapName={child?.wpPublishedAtUrl} titleUuid={child?.titleUuid} uuid={child?.pageUuid} isInContentTopicMap={isInContentTopicMap} setDataLoader={setDataLoader}/>
                                ) : (
                                  <PluginButton style={{cursor: 'not-allowed'}}><FontAwesomeIcon icon={faArrowUpFromLine} color={'#A3A4A4'} fontSize={14} />{title}</PluginButton>
                                )
                            }
                          </>
                        )}
                      </FreezeWrapper>) : (<FreezeWrapper><ViewButton
                        onClick={() => {
                          if (!isLoading && child?.pageUuid && child?.wpPublishedAtUrl) {
                            openUrl(child?.wpPublishedAtUrl, '_blank');
                          } else {
                            return;
                          }
                        }
                        }
                        style={{color: !isLoading && child?.pageUuid && child?.wpPublishedAtUrl ? '#7F4EAD' : '#A3A4A4',
                          backgroundColor: !isLoading && child?.pageUuid && child?.wpPublishedAtUrl ? 'rgba(127, 78, 173, 0.15)' : 'rgba(127, 78, 173, 0)',
                          width: '162px',
                          padding: '5px 8px',
                          cursor: (!isLoading && child?.pageUuid && child?.wpPublishedAtUrl) ? 'pointer' : 'not-allowed',
                        }}>
                        {!isLoading && child?.pageUuid && child?.wpPublishedAtUrl ? (<>
                          <FontAwesomeIcon icon={faCircleCheck} color='#7F4EAD' fontSize={14} />{title}
                        </>) : (<>
                          <FontAwesomeIcon icon={faArrowUpRightFromSquare} color='#A3A4A4' fontSize={14} />{title}
                        </>)}
                      </ViewButton></FreezeWrapper>)}
                  </div>
                  {index !== topicalMapStepsTitles.length -1 && <FontAwesomeIcon icon={faChevronRight} color='#A3A4A4' fontSize={14} />}
                </div>
              ))}
            </div>
          </TopicalMapStepsWrapper>
        </ActionsWrapper>
        <DeleteRapper style={{display: 'flex', alignItems: 'center', minWidth: getDeleteWidth(searchIntentListLength), maxWidth: getDeleteWidth(searchIntentListLength), justifyContent: 'center', paddingTop: '0.625rem'}} isGenerating={isGenerating}>
          {child?.titleUuid === deleteId ? (
            <StyledSpin color='#7F4EAD' />
          ) : (
            <FreezeWrapper removeTooltip={!isLoading && child?.pageUuid && child?.wpPublishedAtUrl}>
              <Tooltip title={isGenerating ? `Can't delete while generating` : ''}>
                <FontAwesomeIcon
                  icon={faTrash}
                  className='trash'
                  fontSize={16}
                  onClick={() => {
                    if (isGenerating) return;
                    setOpenModal(true);
                    setDeleteModalData({id: child?.id, name: child?.name, titleUuid: child?.titleUuid});
                  }}
                />
              </Tooltip>
            </FreezeWrapper>
          )}
        </DeleteRapper>
      </div>
    );
  };

  return (
    <>
      <div style={{height: '100%'}} ref={wpContainerRef}>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
            {!isInContentTopicMap && tableDataFilter}
            {!isInContentTopicMap && <FiltersDropdownWrapper>
              {categoryIntentDropdown}
              {searchIntentDropdown}
              {searchInput}
            </FiltersDropdownWrapper>}
          </div>
          {!isInContentTopicMap && <ExportButton modalStyle={'ottoModalTable'}/>}
        </div>
        <TableWrapper style={{height: '94%'}} className={showClass ? 'show-right-to-left' : ''}>
          <div className={`scroll-arrow ${showClass ? 'visible-scroll' : ''}`} onClick={scrollToEnd}>
            Scroll <FontAwesomeIcon icon={faArrowRight} color='#7F4EAD' fontSize={14}/>
          </div>
          <TableSectionWrapper ref={tableRef}>
            <div style={{marginBottom: '6px', display: 'flex', width: '100%'}}>
              <CategoryTitle className='p-hr p-vr' style={{borderRadius: '8px 0px 0px 8px', minWidth: '250px', maxWidth: '250px'}}>CATEGORY</CategoryTitle>
              <CategoryTitle className='p-vr' style={{minWidth: '94px', maxWidth: '94px'}}>ADD TO<br />TOPIC MAP</CategoryTitle>
              <CategoryTitle className='p-vr' style={{minWidth: getSILength(searchIntentListLength, 'header'), maxWidth: getSILength(searchIntentListLength, 'header')}}>SI</CategoryTitle>
              <CategoryTitle className='p-vr' style={{minWidth: '290px', maxWidth: '290px'}}>KEYWORD</CategoryTitle>
              <CategoryTitle className='p-vr' style={{minWidth: '470px', maxWidth: '470px'}}>TITLE</CategoryTitle>
              <CategoryTitle className='p-hr p-vr' style={{borderRadius: '0px 8px 8px 0px', minWidth: getActionHeaderWidth(searchIntentListLength), maxWidth: getActionHeaderWidth(searchIntentListLength)}}>ACTIONS</CategoryTitle>
            </div>
            <BodyWrapper className='ttt' style={{height: isInContentTopicMap ? '734px' : '98%', width: getTableBodyWidth(searchIntentListLength)}}>
              { !topicalMapDataState?.children?.length ?
                <div style={{height: '70%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><StyledEmpty /></div> :
                topicMapDataList.map((data, index) => {
                  const isExpanded = keysWithChildren.includes(data.key);

                  return (<>{
                    <StyledSingleData key={index}>
                      <div style={{minWidth: '250px', maxWidth: '250px'}}>
                        <SectionTitle onClick={() => onChangeActive(!isExpanded, data?.key)}>
                          <FontAwesomeIcon
                            style={{cursor: 'pointer', marginTop: '4px'}}
                            icon={isExpanded ? faChevronUp : faChevronDown}
                            color='#000000'
                            fontSize={14}
                          />
                          {data?.name}
                        </SectionTitle>
                      </div>
                      <div style={{display: !isExpanded ? 'flex' : 'block', alignItems: !isExpanded ? 'center' : 'flex-start'}}>
                        {!isExpanded ? (
                          <ExpandableCount onClick={() => onChangeActive(!isExpanded, data?.key)}>Expand {flattenChildren(data?.children)?.length} article idea{flattenChildren(data?.children)?.length > 1 ? 's': ''}</ExpandableCount>
                        ) : (
                          data?.children.map(child =>
                            child?.children?.length ? (
                              child?.children.map((grandChild, grandChildIndex) => <IdeaRow key={grandChildIndex} child={grandChild} />)
                            ) : (
                              <IdeaRow key={child?.name || Math.random()} child={child} />
                            ),
                          )
                        )}
                      </div>
                    </StyledSingleData>
                  }
                  </>);
                })}
            </BodyWrapper>
          </TableSectionWrapper>
        </TableWrapper>
      </div>
      {openModal && <Wrapper>
        <Modal
          onClose={() => {
            setOpenModal(false);
            setDeleteModalData(null);
          }}
          visible={openModal}
          className={'customConfirmationModal'}
        >
          <ModalWrapper>
            <p>Are you sure you want to delete this article? </p>
            <p className={'wadeleteArticleIdearningText'}>This action cannot be undone.</p>
            <DeleteButton
              buttonType='transparent' size='lg'
              loading={deletingTopicalMap}
              onClick={async () => deleteArticle(deleteModalData?.name, [...topicalMapArticle, {
                id: deleteModalData?.id,
                titleUuid: deleteModalData?.titleUuid,
                title: deleteModalData?.name,
                isLoading: true,
                pageUuid: '',
              }])}>
            Delete
            </DeleteButton>
            <BackButton onClick={() => {
              setOpenModal(false);
              setDeleteModalData(null);
            }}
            buttonType='transparent'
            size='lg'>
            Go Back
            </BackButton>
          </ModalWrapper>
        </Modal>
      </Wrapper>}
    </>
  );
});

const FilterButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  .ant-dropdown-trigger {
    border: 1px solid #CDCDCD;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: #121212;
    padding: 5px 10px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 6px;
    svg {
      margin-top: -8px;
    }
  }
`;

const FiltersDropdownWrapper = styled(FilterButtons)`
  .ant-dropdown-trigger {
    padding: 3px 10px;
  }
`;

const StyledTag = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 700;
  line-height: normal;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
`;

const Keyword = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: #121212;
  display: flex;
  align-items: center;
  padding: 0px 4px;
  line-height: 18px;
`;

const Title = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #121212;
  display: flex;
  align-items: center;
  padding: 0px 4px;
  line-height: 18px;
`;

const ViewButton = styled.div`
  width: 80px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 6px;
  border-radius: 6px;
  padding: 8px 12px;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #7F4EAD;
  background-color: rgba(127, 78, 173, 0.15);
  line-height: normal;
`;

const TableCheckbox = styled(Checkbox)`
  .ant-checkbox {
    .ant-checkbox-input:focus + .ant-checkbox-inner {
      border-color: #7F4EAD !important;
    }
    .ant-checkbox-inner {
      border-radius: 4px !important;
      &::after {
        top: 42%;
      }
    }
    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: #7F4EAD;
        border-color: #7F4EAD;
      }
    }
    &:hover {
      .ant-checkbox-inner {
        border-color: #7F4EAD !important;
    }
    }
  }
`;

const ExpandableCount = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #2D6CCA;
  line-height: normal;
  cursor: pointer;
`;

const SectionTitle = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #121212;
  padding: 4px 6px;
  display: flex;
  gap: 8px;
  cursor: pointer;
`;

const CategoryTitle = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #121212;
  background-color: #F2F2F5;
  display: flex;
  align-items: center;
  &.p-hr {
    padding-left: 12px;
    padding-right: 12px;
  }
  &.p-vr {
    padding-top: 12px;
    padding-bottom: 12px;
  }
`;

const CheckboxWrapper = styled.div`
  font-family: 'Inter', sans-serif;
  font-size: 12px;
  font-weight: 600;
  color: #121212;
  padding: 0px 0px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const ActionsWrapper = styled.div``;

const BodyWrapper = styled.div`
  overflow: hidden;
  overflow-y: auto;
  height: 410px;
  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(138,138,138,0.32);
  }

  &::-webkit-scrollbar-track {
    background: rgba(138,138,138,0.12);
  }
`;

const StyledSingleData = styled.div`
  width: 100%;
  display: flex;
  box-shadow: 0px 1px 0px 0px #E7E7E7;
`;

export const DeleteRapper = styled.div<{isGenerating: boolean}>`
  .trash {
    color: ${p => p.isGenerating ? '#A3A4A4' : '#A3A4A4'};
    :hover {
      color: ${p => p.isGenerating ? '#A3A4A4' : 'red'};
      cursor: ${p => p.isGenerating ? 'not-allowed' : 'pointer'};
    }
  }
`;
const TableWrapper = styled.div`
  position: relative;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0px;
    z-index: 1100;
    width: 20px;
    box-shadow: inset -20px 0 7px -4px rgba(5, 5, 5, 0.099);
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none; /* Prevent interaction */
  }
  .scroll-arrow {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    color:#7F4EAD;
    position: absolute;
    width: 58px;
    height: 22px;
    top: 2px;
    right: 22px;
    z-index: 1100;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  .scroll-arrow {
    &.visible-scroll {
      opacity: 1 !important;
    }
  }
  &.show-right-to-left::after {
    opacity: 1;
  }
`;
const TableSectionWrapper = styled.div`
  margin-top: 10px;
  height: 100%;
  overflow: hidden;
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(138,138,138,0.32);
  }

  &::-webkit-scrollbar-track {
    background: rgba(138,138,138,0.12);
  }
`;
