import React, {useEffect, useState} from 'react';
import {Collapse, Form, Select, Tooltip} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretDown, faCircleInfo} from '@fortawesome/pro-solid-svg-icons';
import {observer} from 'mobx-react';
import {
  StyledSelectV2,
  RadioGroup,
  WpForm,
  PublishButton,
  WordpressSection,
  Description,
  Heading,
  InfoWrapper,
  StyledInput,
  LeftSection,
  StyledRadio,
  PurpleCheckboxGroup,
  PublishCollapse,
  ImagesBanner,
} from '../styles';
import {useStore} from '@/store/root-store';
import {getDomain} from '@/utils/url';
import {notification} from '@/utils/notification-v2';
import {openUrl} from '@/utils/router';
import FreezeWrapper from '../../../freezTooltip';
import {FreezeBanner} from '../../../ottoHeaderV2';
import {faBoxArchive} from '@fortawesome/pro-duotone-svg-icons';

interface Props {
  isEdit?: boolean;
  downloadHtml?: (excludeHeader: boolean, excludeFooter: boolean) => void;
  setRecord?: (record: any) => void;
  record?: any;
  closeModal?: () => void;
  generating?: boolean;
}
const {Option} = Select;

export const UpdateSection = observer(({downloadHtml, record, closeModal, generating}: Props) => {
  const [wpform] = Form.useForm();
  const [publishing, setPublishing] = useState(false);
  const [activeKey, setActiveKey] = useState('wp');
  const {contentOptimizer: {
    wpPublish: {
      fetchWpPublishData,
      wpArray,
      setShowAddSiteModal,
    },
  },
  ottoV2Store: {
    publishLandingPageToWp,
    getOttoV2Project,
  },
  } = useStore('');

  useEffect(() => {
    fetchWpPublishData();
  }, []);

  const publishToWp = async values => {
    try {
      const exclusions = values?.exclusions || [];
      if (activeKey === 'html') {
        downloadHtml(exclusions?.includes('exclude_header'), exclusions?.includes('exclude_footer'));
        return;
      }
      setPublishing(true);
      const payload = {
        ...values,
        exclude_header: exclusions?.includes('exclude_header'),
        exclude_footer: exclusions?.includes('exclude_footer'),
      };
      if (record?.wpPublishedAtUrl && values.wp_website_id == record?.wpPublishedAtWebsite) {
        payload['post_url'] = record?.wpPublishedAtUrl;
      }
      delete payload['exclusions'];
      await publishLandingPageToWp(record?.uuid, payload);
      notification.success('Success', 'Landing page published successfully');
      closeModal();
      setPublishing(false);
    } catch (error) {
      setPublishing(false);
    }
  };
  const showImagesError = !!(record && record?.isAiGeneratedImages && !record?.isImagesGenerated && record?.cleanedHtml);
  const isPublished = record?.status === 'Published';

  return (
    <LeftSection>
      {(showImagesError && !getOttoV2Project?.isFrozen) && (
        <ImagesBanner>
          <FontAwesomeIcon fontSize={16} icon={faCircleInfo} color='#F1AA3E' />
          <div className='txt'>
             AI Failed to generate Images. You can try Regenerating the Page to fix the problem.
          </div>
        </ImagesBanner>
      )}
      {(getOttoV2Project?.isFrozen && !isPublished) ? <FreezeBanner style={{padding: 8, height: 43}}>
        <FontAwesomeIcon icon={faBoxArchive} color='#F1AA3E' />
        <div className='txt' style={{color: 'rgba(0, 0, 0, 0.85)'}}>
             The changes on this site are frozen. You will be unable to make any modifications.
        </div>
      </FreezeBanner> : ''}
      <Heading>AI Landing Page Builder</Heading>
      <Description>AI-powered tool to create customized local landing pages from your business data in seconds.</Description>
      <div className='wp-wrapper' style={{marginTop: showImagesError ? 12 : 19, position: 'relative'}}>
        {generating && <div style={{position: 'absolute', background: 'rgba(255, 255, 255, 0.45)', top: 0, left: 0, zIndex: 2, width: '100%', height: '100%'}}></div>}
        <InfoWrapper>
          <div>
            <div style={{fontSize: 12, lineHeight: '16px', color: '#4E5156'}}>Page name</div>
            <div style={{fontSize: 14, lineHeight: '16px', color: '#121212'}}>{record?.pageName}</div>
          </div>
          <div>
            <div style={{fontSize: 12, lineHeight: '16px', color: '#4E5156'}}>City</div>
            <div style={{fontSize: 14, lineHeight: '16px', color: '#121212'}}>{record?.city}</div>
          </div>
          <div>
            <div style={{fontSize: 12, lineHeight: '16px', color: '#4E5156'}}>What are the services you offer?</div>
            <div style={{fontSize: 14, lineHeight: '16px', color: '#121212'}}>{record?.services?.join(', ')}</div>
          </div>
        </InfoWrapper>
        {record?.cleanedHtml && <div style={{marginTop: 20}}>
          <WpForm
            form={wpform}
            onFinish={publishToWp}
            initialValues={{
              wp_website_id: record?.wpPublishedAtWebsite || wpArray?.[0]?.id,
              post_status: record?.wpPostStatus || 'publish',
              permalink: record?.wpUrlSlug || '',
              exclusions: [record?.excludeHeader && 'exclude_header', record?.excludeFooter && 'exclude_footer'].filter(item => item),
            }}
          >
            <div style={{fontSize: 12, lineHeight: '16px', color: '#4E5156', marginTop: 20}}>Export options</div>
            <FreezeWrapper removeTooltip={isPublished}>
              <Form.Item name='exclusions'>
                <PurpleCheckboxGroup disabled={publishing} options={[{label: 'Exclude Header', value: 'exclude_header'}, {label: 'Exclude Footer', value: 'exclude_footer'}]} />
              </Form.Item>
            </FreezeWrapper>

            <div style={{fontSize: 12, lineHeight: '16px', color: '#4E5156', marginTop: 20}}>Publish to:</div>
            <PublishCollapse
              activeKey={[activeKey]}
              onChange={key => setActiveKey(prev => key ? key as string : prev)}
              accordion
              destroyInactivePanel
              expandIcon={props => <StyledRadio checked={props?.isActive} disabled={props?.collapsible === 'disabled'} />}
            >
              <Collapse.Panel
                extra={activeKey === 'wp' ? <FreezeWrapper removeTooltip={isPublished}><div className='add-btn' onClick={() => setShowAddSiteModal(true)}>+ Add site</div></FreezeWrapper> : ''}
                header={(
                  <div style={{display: 'flex', alignItems: 'center', gap: 6}}>
                    Wordpress
                    {(record?.status == 'Published' && record?.wpPostStatus === 'publish') && <div style={{color: '#18923B', fontSize: 12, backgroundColor: 'rgba(24, 146, 59, 0.15)', paddingInline: 8, minHeight: 20, borderRadius: 55, display: 'flex', alignItems: 'center', lineHeight: 0}}>Published</div>}
                  </div>
                )}
                key='wp'
              >
                <WordpressSection>
                  {record?.wpPublishedAtUrl && <div style={{fontSize: 12, fontWeight: 600, marginBottom: 8, display: 'flex', alignItems: 'center', gap: 6}}>
                    URL:
                    <Tooltip title={record?.wpPublishedAtUrl} overlayStyle={{maxWidth: 350}} overlayInnerStyle={{backgroundColor: '#000', borderRadius: 6}}>
                      <span onClick={() => openUrl(record?.wpPublishedAtUrl, '_blank')} style={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', cursor: 'pointer', color: '#2D6CCA', fontWeight: 400}}>{record?.wpPublishedAtUrl}</span>
                    </Tooltip>
                  </div>}
                  <div>
                    <Form.Item className='wp-field' label='Website' name='wp_website_id' rules={[{required: true, message: ''}]}>
                      <StyledSelectV2 getPopupContainer={trigger => trigger.parentNode} suffixIcon={<FontAwesomeIcon icon={faCaretDown} color='#121212' />} disabled={publishing}>
                        {wpArray?.map((wpSite, idx) => {
                          return <Option on value={wpSite?.id} key={idx}>{getDomain(wpSite?.url)}</Option>;
                        })}
                      </StyledSelectV2>
                    </Form.Item>
                    <Form.Item className='wp-field' label='Permalink URL' name='permalink' rules={[{required: true, message: 'Permalink is required'}, {whitespace: true, message: ''}, {pattern: /^(\/)?[a-zA-Z0-9\-_.~]+(\/[a-zA-Z0-9\-_.~]+)*(\/)?(\?.*)?$/, message: 'Please enter a valid permalink'}]}>
                      <StyledInput autoComplete='off' height='32px' br='4px' placeholder='' disabled={publishing} />
                    </Form.Item>
                  </div>
                  <Form.Item style={{marginTop: 6}} label='Page Status' name='post_status' rules={[{required: true, message: ''}]}>
                    <RadioGroup options={[{label: 'Draft', value: 'draft'}, {label: 'Published', value: 'publish'}]} disabled={publishing} />
                  </Form.Item>
                  <div style={{marginTop: 12}}>
                    <FreezeWrapper>
                      <PublishButton height='32px' br='8px' disabled={publishing} loading={publishing} htmlType='submit'>Publish Landing Page</PublishButton>
                    </FreezeWrapper>
                  </div>
                </WordpressSection>
              </Collapse.Panel>
              <Collapse.Panel header={(
                <Tooltip placement='topLeft' overlayInnerStyle={{backgroundColor: '#000', borderRadius: 6}} title='Coming soon'>
                  <div style={{width: '100%'}}>Shopify</div>
                </Tooltip>
              )} key='shopify' collapsible='disabled'>Coming Soon</Collapse.Panel>
              <Collapse.Panel disabled={publishing} header='Raw HTML' key='html'>
                <FreezeWrapper removeTooltip={isPublished}>
                  <PublishButton htmlType='submit' br='8px' width='159px' height='32px'>Download as HTML</PublishButton>
                </FreezeWrapper>
              </Collapse.Panel>
            </PublishCollapse>
          </WpForm>
        </div>}
      </div>
    </LeftSection>
  );
});
