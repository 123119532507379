import {Button} from '@/components/common-components';
import {faCheckCircle, faHistory, faText, faRectanglesMixed, faWrenchSimple, faNewspaper, faCircleNodes, faMapPin, faCaretDown, faGear, faListTree} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Col, Dropdown, Row, Tooltip, Input, Radio, Empty} from 'antd';
import {observer} from 'mobx-react';
import styled from 'styled-components';
import {TableIssuesCollapse} from './tableIssuesCollapse';
import {IssueCategoriesCollapse} from './issueCategoryCollapse';
import {useStore} from '@/store/root-store';
import {camelCaseToHumanize, camelToSnakeCase, snakeToCamelCase, titleCase, truncate} from '@/utils/string';
import {getSingleUrlParam} from '@/utils/url';
import {notification} from '@/utils/notifications';
import {newNotification} from '@/utils/notification-v3';
import {notification as notificationV2} from '@/utils/notification-v2';
import {useState, useEffect, useRef, Dispatch, SetStateAction} from 'react';
import {Tabs, TabPane, CommonDivider, dividerType} from '@/components/common-components/components';
import {useRouter} from 'next/router';
import {Pill} from '@/components/layoutV2/user-menu/styles';
import {faBan, faBrowser, faCircleInfo, faMagnifyingGlass, faPlus} from '@fortawesome/pro-regular-svg-icons';
import {QuotaInfoDetail} from '@/components/common-components/v2/quotaInfo';
import {TopupModal} from '../Modals/topupModal';
// import {faCoins} from '@fortawesome/pro-duotone-svg-icons';
import {faComments} from '@fortawesome/pro-duotone-svg-icons';

import {
  faWrench,
  faMagnifyingGlassPlus,
  faLocationDot,
  faArrowUpRightDots,
} from '@fortawesome/free-solid-svg-icons';
import {AutoRespondModal} from '../Modals/autoRespondModal';
import {faCoins} from '@fortawesome/pro-duotone-svg-icons';
import {businessAddress, publicPageCategories, showAddButtonLabel} from '../Constants';
import {BusinessInfoModal} from './GbpAudit/AggregatorNetwork/AggregatorNetwork/businessInfoModal';
import {CompletionModal} from './GbpAudit/AggregatorNetwork/AggregatorNetwork/completionModal';
import {AddServiceAccountButton} from './styleComponents';
import AddGscServiceAccModal from './addGscServiceAccModal';
import BlockListModal from '../../campaigns/createCampaignModal/sections/blockListModal';
import {CreateCampaignModal} from '../../campaigns/createCampaignModal';
import {ButtonStyled} from './customHtmlPopover';
import {LandingPageGeneratorModal} from './GbpAudit/LandingPageGenerator/LandingPageGeneratorModal';
import {ViewCitationModal} from './GbpAudit/AggregatorNetwork/AggregatorNetwork/viewCitationModal';
import FreezeWrapper from './freezTooltip';
import moment from 'moment';
import {DeployContainer, StyledSwitch} from './GbpAudit/GbpOptimization/styles';
import {GBPAutomationModal} from '../Modals/gbpAutomationModal';
import {apiError} from '@/utils/api';
import {LargeBadge} from '@/components/common-components/components/badge';
import {GBPMediaLibraryModal} from './GbpAudit/GbpPosts/GbpPosts/gbpMediaLibraryModal';

type optionsType = 'quantity'
| 'price'
| 'additionally_included'
| 'current_quota'
| 'used_card'
| 'recurring_payment'
;

interface TopUpVisibilityProps {
  visibility: boolean;
  price: number;
  title: string;
  options: optionsType[];
  consumed: number | null;
  total: number | null;
  recurring: string;
  quotaKey: string;
  desc: string;
  nextPaymentAt?: string | null;
  buttonTxt: string;
  step?: number | null;
  localSeo?: boolean;
  overAllQuota?: number;
  multiplesOfStep?: boolean;
  unitIsMultipleOfStep?: boolean;
  recurringIsOptional?: boolean;
  recurringValue?: string;
  setRecurringValue?: Dispatch<SetStateAction<'no' | 'yes'>>;
  wholeCredits?: boolean;
}

export const getHyperDriveTooltip = data => (
  <TooltipWrapper>
    <table className='time-saved-table'>
      <thead className='table-head'>
        <tr className='table-row'>
          <th className='title text-left'>QUOTA TYPE</th>
          <th className='title text-right'>TOTAL</th>
          <th className='title text-right'>USED</th>
        </tr>
      </thead>
      <tbody className='table-body'>
        {data?.map((item, index) => {
          return (<tr className='table-row' style={{marginTop: 6, marginBottom: 6}} key={index}>
            <td style={{width: 'auto', minWidth: 156, maxWidth: 240, textTransform: 'capitalize'}} className={`quota-name color-white`}>
              <div className='inner-text'>{item?.label}</div>
            </td>
            <td style={{fontSize: 12, minWidth: 60}} className={` text-right color-white`}>
              {item?.total}
            </td>
            <td style={{fontSize: 12, minWidth: 60}} className={`text-right color-white`}>
              {item?.used}
            </td>
          </tr>);
        })}
      </tbody>
    </table>
  </TooltipWrapper>
);


const tabsWithIcons = [
  {label: 'Technical Fixes', icon: faWrench},
  {label: 'Content Optimizations', svg: 'faFilePlus'},
  {label: 'New Content', svg: 'faMemoCircleCheck'},
  {label: 'Schema Markup', icon: faMagnifyingGlassPlus},
  {label: 'Google Business Profile (GBP) Optimization', icon: faLocationDot},
  {label: 'Authority Building', icon: faArrowUpRightDots},
];
const gbpList = ['gbp_posts', 'qanda_suggestions', 'cloud_stacks', 'topical_maps_and_supplemental_content', 'unanswered_reviews', 'press_release_distribution', 'ai_landing_page_builder', 'aggregator_network', 'campaigns', 'gsc_instant_indexing', 'dynamic_indexing', 'inbox', 'templates'];
const hideDeployButtons = ['custom_content', 'inbox', 'campaigns', 'templates'];
export const ContentDetailV2 = observer(() => {
  const {ottoV2Store: {
    getOttoUrls,
    selectedCategory,
    getOttoV2Project,
    getSelectedLocation,
    deployOttoUrls,
    setIssueTypeArray,
    selectedIssue,
    getOttoV2ProjectsList,
    setDefaultParams,
    loadOttoV2Project,
    setIsDeploying,
    setDeployRollBackAll,
    setGbpShouldRepoll,
    quotaAllocations,
    setGbpLocationRepoll,
    setGbpLocationDetail,
    setNlpFaqShouldRepoll,
    setNlpTermsShouldRepoll,
    gbpLocationDetail,
    setSelectedLocation,
    activateIndexing,
    navigatedFromGbpOptimizations,
    gbpAutomatedPostSettings,
    updatedGbpAutomatedPostSettings,
    getGbpAutomatedPostSettings,
    getGbpMediaLibraryImages,
  },
  campaigns: {
    setFoldersModalVisible,
    setCreateModalVisible,
    setCreateTempModalVisible,
    isEditModal,
  },
  contentOptimizer: {wpPublish: {
    fetchWpPublishData}},
  settings: {customer: {profile: {whitelabelOtto, quotaUtilization, nextPaymentAt, isLinkgraph, trialExpiresAt}}},
  } = useStore('');
  const isPublicPage = !!getSingleUrlParam('public_hash');
  const groups = getOttoV2Project?.issuesCountBreakdown['groups'] || {};
  const uuid = getSingleUrlParam('uuid');
  const category = getSingleUrlParam('category');
  const [showSearch, setShowSearch] = useState(-1);
  const issueTypesFrontendMappingV2 = getOttoV2Project?.issueTypesFrontendMappingV2;
  const [currentProject, setCurrentProject] = useState(null);
  const categories = selectedIssue == 'All' ? issueTypesFrontendMappingV2?.find(group=> group?.subGroups?.filter(g=> g?.group == selectedCategory)?.length)?.group : selectedIssue;
  const issueObject = issueTypesFrontendMappingV2?.find(issueObj => issueObj?.group === categories || issueObj?.label === categories)?.subGroups?.find(categoryObj => categoryObj?.group === selectedCategory);
  const [activeTab, setActiveTab] = useState('All');
  const [tabsArray, setTabsArray] = useState([]);
  const [showComingSoon, setShowComingSoon] = useState(false);
  const [openGenerateGbpPostsModal, setOpenGenerateGbpPostsModal] = useState(false);
  const [openGbpLibraryModal, setOpenGbpLibraryModal] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [showGenerateBulkHeader, setShowGenerateBulkHeader] = useState(false);
  const [topUpBulkVisibility, setTopUpBulkVisibility] = useState<TopUpVisibilityProps>({
    visibility: false,
    price: 0,
    title: '',
    options: [],
    consumed: 0,
    total: 0,
    recurring: '',
    quotaKey: '',
    desc: '',
    buttonTxt: '',
    step: 0,
    nextPaymentAt: null,
    unitIsMultipleOfStep: false,
    multiplesOfStep: false,
    overAllQuota: null,
  });

  const router = useRouter();
  const [openAutoRespondModal, setOpenAutoRespondModal] = useState(false);
  const [openBusinessInfoModal, setOpenBusinessInfoModal] = useState(false);
  const [openCompletionModal, setOpenCompletionModal] = useState({
    visible: false,
    data: {},
  });
  const [submittedBusinessData, setSubmittedBusinessData] = useState<any>({});
  const [openLandingPageModal, setOpenLandingPageModal] = useState(false);
  const [showGbpDropdown, setShowGbpDropdown] = useState(false);
  const [selectedGbp, setSelectedGbp] = useState<any>({});
  const [hyperDriveRecurring, setHyperDriveRecurring] = useState<'yes' | 'no'>('no');
  const [blackListModalVisible, setBlackListModalVisible] = useState(false);
  const [campaignsId, setCampaignsId] = useState('');
  const tableKeys = getOttoUrls?.map((url, index) => {
    if (url?.issueTable?.results?.length) {
      return (index + 1).toString();
    }
  });
  const disableDeployButton = tableKeys.filter(activeKey => activeKey !== undefined);
  const sectionRef = useRef<HTMLDivElement>(null);
  const [showAddServiceAccount, setShowAddServiceAccount] = useState(false);
  const [activatingIndexing, setActivatingIndexing] = useState(false);
  const [email, setEmail] = useState(null);
  const [openCitationModal, setOpenCitationModal] = useState(false);
  const [openGBPAutomationModal, setOpenGBPAutomationModal] = useState(false);
  const [automatedGbpPostSettingLoader, setAutomatedGbpPostSettingLoader] = useState(false);
  const headerButtons = {
    unanswered_reviews: {
      icon: faComments,
      text: 'Auto Respond to Reviews',
      style: {},
      fn: () => {
        setGbpLocationDetail(null);
        setOpenAutoRespondModal(true);
      },
    },
    ai_landing_page_builder: {
      icon: faPlus,
      text: 'Generate a page',
      style: {borderRadius: 6},
      fn: () => setOpenLandingPageModal(true),
    },
  };

  useEffect(() => {
    if (!getOttoV2ProjectsList?.results?.length) {
      setDefaultParams({search: '', pageSize: 20, page: 1});
    }
    if (category) {
      setActiveTab(category);
    } else {
      router.replace({
        query: {...router.query, category: 'All'},
      });
    }
    if (!isPublicPage) checkWpSites();
  }, []);
  useEffect(() => {
    if (issueTypesFrontendMappingV2?.length) {
      const mapping = isPublicPage ? issueTypesFrontendMappingV2?.filter(item => publicPageCategories.includes(item?.group)) : issueTypesFrontendMappingV2;
      setTabsArray(mapping?.filter(issue => isLinkgraph ? true: !issue?.isComingSoon).map(issue => {
        return {
          label: issue?.label,
          group: issue?.group,
        };
      }));
    }
  }, [issueTypesFrontendMappingV2]);

  useEffect(() => {
    setCurrentProject(getOttoV2Project?.uuid == uuid && getOttoV2Project);
  }, [getOttoV2Project]);

  useEffect(() => {
    if (selectedCategory === 'gbp_posts') {
      setGbpShouldRepoll(true);
    }
    setCampaignsId('');
    return () => {
      setGbpShouldRepoll(false);
    };
  }, [selectedIssue, selectedCategory]);

  const checkWpSites = async () => {
    await fetchWpPublishData();
  };

  useEffect(() => {
    setSearchValue('');
    if (['unanswered_reviews', 'qanda_suggestions', 'gbp_posts', 'press_release_distribution'].includes(selectedCategory)) {
      setGbpLocationRepoll(true);
    }
    if (['knowledge_based_trust'].includes(selectedCategory)) {
      setNlpFaqShouldRepoll(true);
    } else if (['semantic_analysis'].includes(selectedCategory)) {
      setNlpTermsShouldRepoll(true);
    }
    return () => {
      setGbpLocationRepoll(false);
      setNlpFaqShouldRepoll(false);
      setNlpTermsShouldRepoll(false);
    };
  }, [selectedCategory]);

  useEffect(() => {
    if (!selectedGbp?.businessName) {
      if (getSelectedLocation()['businessName']) {
        setSelectedGbp(getSelectedLocation());
        setSelectedLocation(getSelectedLocation());
      } else if (getOttoV2Project?.connectedData?.gbpDetailsV2?.length) {
        setSelectedGbp(getOttoV2Project?.connectedData?.gbpDetailsV2[0]);
        setSelectedLocation(getOttoV2Project?.connectedData?.gbpDetailsV2[0]);
      }
    }
    if (selectedGbp?.businessName && getOttoV2Project?.connectedData?.gbpDetailsV2?.length) {
      const hasRemoved = selectedGbp?.businessName !== getOttoV2Project?.connectedData?.gbpDetailsV2[0]?.businessName;
      if (hasRemoved && !navigatedFromGbpOptimizations) {
        setSelectedGbp(getOttoV2Project?.connectedData?.gbpDetailsV2[0]);
        setSelectedLocation(getOttoV2Project?.connectedData?.gbpDetailsV2[0]);
      }
    }
  }, [getOttoV2Project?.connectedData?.gbpDetailsV2, router.query?.subGroup]);

  // This function is used to deploy/roll back an entire group/category
  const deployOttoCategory = async (toDeploy, issueGroup) => {
    setDeployRollBackAll(true);
    if (currentProject && (currentProject?.pixelTagState == 'not_installed' || currentProject?.pixelTagState == 'wrong_uuid')) {
      notificationV2.warning('', `To enable changes, you must install the ${whitelabelOtto} Pixel`);
      return;
    }
    try {
      await deployOttoUrls({toDeploy, issueGroup: camelToSnakeCase(issueGroup), uuid});
      if (issueTypesFrontendMappingV2 && issueTypesFrontendMappingV2.length > 0) {
        const array = issueTypesFrontendMappingV2
          .find(issue => issue.group === selectedIssue)?.subGroups
          .find(issue => issue.group === selectedCategory)
          ?.subGroups?.map((subGroup: any) => subGroup?.group);
        setIssueTypeArray(array ?? []);
      }
      setIsDeploying(true);
      if (uuid) {
        loadOttoV2Project(uuid, true);
      }

      let message = '';
      if (toDeploy) {
        message = currentProject?.isEngaged ? `All generated content is deployed` : `All generated content is enabled`;
        newNotification(`${titleCase(camelCaseToHumanize(issueGroup))}: ${message}`, 2, 'deploy');
      } else {
        message = `All changes rolled back`;
        newNotification(`${titleCase(camelCaseToHumanize(issueGroup))}: ${message}`, 2, 'rollback');
      }
    } catch (error) {
      notification.error('Something went wrong please try again', 2);
    }
  };
  const tabIcon = issue => {
    const tabWithIcon = tabsWithIcons.find(tab => tab.label == issue);
    if (tabWithIcon) {
      if (tabWithIcon.icon) {
        return tabWithIcon.icon;
      } else {
        return tabWithIcon.svg;
      }
    }
  };

  const scrollToSection = () => {
    if (sectionRef.current) {
      window.scrollTo({
        top: sectionRef.current.offsetTop,
        behavior: 'smooth',
      });
    }
  };

  const iconMapping = {
    'https://fontawesome.com/icons/text?f=classic&s=solid': faText,
    'solid-Browser': faBrowser,
    'solid-RectanglesMixed': faRectanglesMixed,
    'solid-WrenchSimple': faWrenchSimple,
    'solid-Newspaper': faNewspaper,
    'solid-CircleNodes': faCircleNodes,
    'solid-MapPin': faMapPin,
  };

  const staticData = {
    'Local Landing Pages': {
      description: 'Creates customized landing pages for different geographical locations to target local audiences more effectively, improving local search rankings and increasing engagement.',
      color: 'rgba(127, 78, 173, 1)',
      background: 'rgba(127, 78, 173, 15%)',
    },
    'Press Release Distribution': {
      description: 'Distributes your press releases to a wide network of media outlets, increasing brand exposure and backlink opportunities to improve SEO.',
      color: 'rgba(45, 108, 202, 1)',
      background: 'rgba(45, 108, 202, 15%)',

    },
    'Local Citations Aggregator Network': {
      description: 'Aggregates and manages local citations across numerous platforms to enhance local search engine visibility and accuracy of your business information.',
      color: 'rgba(45, 108, 202, 1)',
      background: 'rgba(45, 108, 202, 15%)',
    },
    'Guest Posts / Link Building': {
      description: 'Streamlines the process of acquiring quality backlinks from reputable sites, boosting your website’s authority and search engine ranking.',
      color: 'rgba(127, 78, 173, 1)',
      background: 'rgba(127, 78, 173, 15%)',
    },
    'Metadata Optimizations': {
      description: 'Refines metadata in your Google Business Profile for increased relevance in local searches, helping you stand out in competitive markets.',
      color: 'rgba(45, 108, 202, 1)',
      background: 'rgba(45, 108, 202, 15%)',
    },
  };

  const categoriesComingSoon = issueTypesFrontendMappingV2?.flatMap(category => category?.subGroups)?.filter(issue => issue?.isComingSoon);
  const renderTooltip = () => ( currentProject?.pixelTagState == 'installed' && currentProject?.isEngaged ?
    <>
      <span>Deployment might take a few minutes before the changes are visible on your site.</span>
      <br/>
      <span>{`Additionally, the count of fixed issues in the category list won't update immediately.`}</span>
    </> : <span>Deployment will be done after enabling {whitelabelOtto} SEO Widget.</span>
  );

  const getTopupQuotaConf = key => {
    return quotaAllocations?.find(i => i?.quotaKey === key);
  };

  const getSummedHyperDriveQuotaValues = key => {
    return (quotaUtilization?.ca?.hyperdriveCredits[key] ?? 0) + (quotaUtilization?.ca?.hyperdriveCreditsPurchased[key] ?? 0);
  };

  const quotas = {
    cloud_stacks: [
      {
        consumedData: getSummedHyperDriveQuotaValues('consumed'),
        totalData: getSummedHyperDriveQuotaValues('total'),
        recurringTotalCount: quotaUtilization?.ca?.hyperdriveCredits?.total,
        recurringConsumedCount: quotaUtilization?.ca?.hyperdriveCredits?.consumed,
        nonRecurringTotalCount: quotaUtilization?.ca?.hyperdriveCreditsPurchased?.total,
        nonRecurringConsumedCount: quotaUtilization?.ca?.hyperdriveCreditsPurchased?.consumed,
        heading: 'HyperDrive Credits',
        description: `
          Quota Utilization
          HyperDrive Credits`,
        hideTopup: true,
        forHeatmap: true,
        showCta: true,
        ctaLabel: 'Top up',
        ctaIcon: <FontAwesomeIcon icon={faCoins} color='#2AC155' style={{marginRight: '5px'}} />,
        varient: 'green',
        customCta: () => {
          const cloudStacksConf = getTopupQuotaConf('quota_ca_hyperdrive_credits');
          setTopUpBulkVisibility({
            visibility: true,
            price: hyperDriveRecurring === 'yes' ? (cloudStacksConf?.pricePerUnit || 0) * 120 : 1,
            title: `${whitelabelOtto} HyperDrive`,
            options: isLinkgraph ? ['price', 'current_quota', 'used_card', 'recurring_payment'] : ['quantity', 'current_quota', 'used_card', 'recurring_payment'],
            recurring: hyperDriveRecurring === 'yes' ? cloudStacksConf?.topOptions[0] : 'purchased',
            quotaKey: cloudStacksConf?.quotaKey,
            desc: hyperDriveRecurring === 'yes' ? 'per 120 credits per month' : 'per 1 credit',
            nextPaymentAt: nextPaymentAt,
            buttonTxt: 'site',
            total: getSummedHyperDriveQuotaValues('total'),
            consumed: getSummedHyperDriveQuotaValues('consumed'),
            step: hyperDriveRecurring === 'yes' ? 120 : 1,
            multiplesOfStep: hyperDriveRecurring === 'yes' ? true : false,
            unitIsMultipleOfStep: hyperDriveRecurring === 'yes' ? true : false,
            recurringIsOptional: true,
            recurringValue: hyperDriveRecurring,
            setRecurringValue: setHyperDriveRecurring,
            wholeCredits: true,
          });
        },
      },
    ],
    press_release_distribution: [
      {
        consumedData: getSummedHyperDriveQuotaValues('consumed'),
        totalData: getSummedHyperDriveQuotaValues('total'),
        heading: 'HyperDrive Credits',
        recurringTotalCount: quotaUtilization?.ca?.hyperdriveCredits?.total,
        recurringConsumedCount: quotaUtilization?.ca?.hyperdriveCredits?.consumed,
        nonRecurringTotalCount: quotaUtilization?.ca?.hyperdriveCreditsPurchased?.total,
        nonRecurringConsumedCount: quotaUtilization?.ca?.hyperdriveCreditsPurchased?.consumed,
        description: `
          Quota Utilization
          HyperDrive Credits`,
        hideTopup: true,
        forHeatmap: true,
        showCta: true,
        ctaLabel: 'Top up',
        ctaIcon: <FontAwesomeIcon icon={faCoins} color='#2AC155' style={{marginRight: '5px'}} />,
        varient: 'green',
        customCta: () => {
          const cloudStacksConf = getTopupQuotaConf('quota_ca_hyperdrive_credits');
          setTopUpBulkVisibility({
            visibility: true,
            price: hyperDriveRecurring === 'yes' ? (cloudStacksConf?.pricePerUnit || 0) * 120 : 1,
            title: `${whitelabelOtto} HyperDrive`,
            options: isLinkgraph ? ['price', 'current_quota', 'used_card', 'recurring_payment'] : ['quantity', 'current_quota', 'used_card', 'recurring_payment'],
            recurring: hyperDriveRecurring === 'yes' ? cloudStacksConf?.topOptions[0] : 'purchased',
            quotaKey: cloudStacksConf?.quotaKey,
            desc: hyperDriveRecurring === 'yes' ? 'per 120 credits per month' : 'per 1 credit',
            nextPaymentAt: nextPaymentAt,
            buttonTxt: 'site',
            total: getSummedHyperDriveQuotaValues('total'),
            consumed: getSummedHyperDriveQuotaValues('consumed'),
            step: hyperDriveRecurring === 'yes' ? 120 : 1,
            multiplesOfStep: hyperDriveRecurring === 'yes' ? true : false,
            unitIsMultipleOfStep: hyperDriveRecurring === 'yes' ? true : false,
            recurringIsOptional: true,
            recurringValue: hyperDriveRecurring,
            setRecurringValue: setHyperDriveRecurring,
            wholeCredits: true,
          });
        },
      },
    ],
  };

  const handleSelectedLocation = e => {
    setSelectedGbp(e);
    setSelectedLocation(e);
    if (issueObject?.group === 'gbp_posts') {
      getGbpAutomatedPostSettings();
      getGbpMediaLibraryImages({page: 1, pageSize: 7}, false);
    }
  };

  useEffect(() => {
    if (topUpBulkVisibility?.visibility) {
      quotas[issueObject?.group]?.[0]?.customCta();
    }
  }, [hyperDriveRecurring]);

  const filteredLocations = getOttoV2Project?.connectedData?.gbpDetailsV2?.filter(d => searchValue ? d?.businessName?.toLowerCase()?.includes(searchValue?.toLowerCase()) : true);

  const locationList = (
    <DropdownContainer>
      <GbpSearchInput onChange={e => setSearchValue(e.target.value)} value={searchValue} placeholder='Search...'
        prefix={<FontAwesomeIcon icon={faMagnifyingGlass} fontSize={'12px'} color='#A3A4A4' />}
      />

      {/* <Radio.Group style={{width: '100%', margin: '10px 0'}} onChange={(e)=> handleSelectedLocation(e.target.value)}> */}
      <DropdownListContainer>
        {filteredLocations?.length ? filteredLocations?.map(business => (
          <Radio key={business?.locationId} style={{width: '100%'}} onChange={e=> handleSelectedLocation(e.target.value)} checked={String(selectedGbp?.id) === String(business?.id)} value={business}>
            <div>
              <div style={{fontWeight: 'bold', fontSize: '12px'}}>{business?.businessName}</div>
              <p style={{color: '#4E5156', fontSize: '12px', fontFamily: 'Inter', fontWeight: '400'}}>{business?.businessAddress}</p>
            </div>
          </Radio>
        )) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
        {/* </Radio.Group> */}
      </DropdownListContainer>
    </DropdownContainer>
  );

  useEffect(() => {
    router.query.category = activeTab;
  }, [activeTab]);

  const trialExpiresAtDateCheck = moment(trialExpiresAt)?.isValid() ? moment().isAfter(moment(trialExpiresAt)) : true;

  const updateAutomatedPostSettingStatus = async (state: boolean) => {
    try {
      setAutomatedGbpPostSettingLoader(true);
      await updatedGbpAutomatedPostSettings({is_active: state});
      setAutomatedGbpPostSettingLoader(false);
      notificationV2.success('', `Automated posting ${state ? 'enabled' : 'disabled'} successfully`);
    } catch (e) {
      setAutomatedGbpPostSettingLoader(false);
      const errorMessage = apiError(e);
      notificationV2.error('', errorMessage);
    }
  };

  return (
    <>
      <Header>
        <div className='recommended-text'>Recommendations by Category</div>
        <StyledTabs
          tabBarGutter={5}
          activeKey={activeTab}
          tabBarStyle={{'padding': '0 !important'}}
          defaultActiveKey={'All'}
          onChange={key => {
            setActiveTab(key);
            setShowComingSoon(false);
          }}
          moreIcon={null}
        >

          <TabPane tab='All' key='All' style={{'outline': 'none'}}/>
          {tabsArray.length && tabsArray.map(issue => {
            const icon = tabIcon(issue?.label);
            return (
              <TabPane tab={
                <span>{
                  typeof(icon) == 'string' ?
                    <img style={{marginRight: 7, marginTop: '-2px'}} src={`/icons/ottoIcons/${icon}.svg`}/> :
                    <FontAwesomeIcon style={{marginRight: 7, color: 'rgba(163, 164, 164, 1)'}} icon={icon}/>
                }
                {issue?.label}
                </span>
              }
              key={issue?.group}
              style={{'outline': 'none'}}
              />
            );
          })}
        </StyledTabs>
      </Header>
      <WhiteSectionWrapper ref={sectionRef}>
        <Row>
          <Col md={6}>
            <SectionHeading>Issue Categories</SectionHeading>
            <IssueCategoriesCollapse scrollToSection={scrollToSection} showSearch={showSearch} setShowSearch={setShowSearch} activeTab={activeTab} setShowComingSoon={setShowComingSoon} showComingSoon={showComingSoon} comingSoonCount={categoriesComingSoon?.length} campaignsId={campaignsId} setCampaignsId={setCampaignsId}/>
          </Col>
          <Col md={18}>
            {showComingSoon ? (
              <Row>
                <Col span={24}>
                  <SectionHeading>Coming soon</SectionHeading>
                </Col>
                <Col span={24}>
                  <ComingSoonListWrapper>
                    {categoriesComingSoon.map((item, index) => (
                      <StyledComingSoonItem key={index} >
                        <div className='icon' style={{backgroundColor: staticData[item.label]?.background ?? 'rgba(255, 133, 54, 15%)'}}>
                          <FontAwesomeIcon icon={iconMapping[item?.icon] ?? faText} color={staticData[item.label]?.color ?? 'rgba(255, 133, 54, 1)'} fontSize={24} />
                        </div>
                        <div className='content'>
                          <div className='title'>{item.label == 'Metadata Optimizations' ? 'GBP Metadata Optimization' : item.label}</div>
                          <div className='description'>{staticData[item.label]?.description ?? `Automatically adjusts your website's indexing settings based on real-time search engine updates, ensuring optimal visibility. This feature aims to keep your site prominently ranked despite frequent algorithm changes.`}</div>
                        </div>
                        <div className='tag-wrapper'>
                          <Pill className='tag'>Coming Soon</Pill>
                        </div>
                      </StyledComingSoonItem>
                    ))}
                  </ComingSoonListWrapper>
                </Col>
              </Row>) :
              ((!getOttoV2Project?.isInstantIndexingAllowed || !isLinkgraph) && issueObject?.group === 'gsc_instant_indexing') ? <></> : <>
                <FilterWrapper style={{display: campaignsId ? 'none': ''}}>
                  <Row style={{width: '100%', justifyContent: 'space-between', margin: '0px 2px 0px 4px'}}>
                    <StyledCol style={{display: 'flex', alignItems: 'center', justifyContent: (issueObject?.group === 'unanswered_reviews' || quotas[issueObject?.group]?.length) && 'space-between'}} span={24}>
                      {selectedCategory && <div style={{display: 'flex', maxWidth: 800, ...(getOttoV2Project?.isFrozen && {width: '100%'}), flexWrap: 'wrap'}}>
                        <SectionHeading style={{marginBottom: '0px', display: 'flex', alignItems: 'center'}}>{issueObject?.group == 'profile_optimizations' ? 'GBP Optimizations' : (issueObject?.label + ' ' + ((!gbpList.includes(issueObject?.group) && (issueObject?.group !== 'links')) ? 'Issues' : ''))}</SectionHeading>
                        {businessAddress.includes(issueObject?.group) &&
                          <Tooltip title={selectedGbp?.businessName?.length > 30 ? selectedGbp?.businessName : null}>
                            <Dropdown trigger={['click']} overlay={locationList} visible={showGbpDropdown} onVisibleChange={e => setShowGbpDropdown(e)}>
                              <DropdownWrapper>
                                <FontAwesomeIcon icon={faLocationDot} fontSize={14}/>
                              Business:
                                <div>{truncate(selectedGbp?.businessName, 30)}</div>
                                <FontAwesomeIcon icon={faCaretDown} fontSize={14}/>
                              </DropdownWrapper>
                            </Dropdown>
                          </Tooltip>
                        }
                        <IssueTitle className='issue-count'>
                          {
                            groups[snakeToCamelCase(selectedCategory)] ? groups[snakeToCamelCase(selectedCategory)]?.total === 0 ? <IssueCount>No issues found</IssueCount> :
                              <>
                                {
                                  groups[snakeToCamelCase(selectedCategory)] ?
                                    <IssueCount>{groups[snakeToCamelCase(selectedCategory)]?.approved} of {groups[snakeToCamelCase(selectedCategory)]?.total} fixed</IssueCount> :
                                    <IssueCount></IssueCount>
                                }
                              </>: '' }
                        </IssueTitle>
                      </div>}
                      {(issueObject?.group === 'topical_maps_and_supplemental_content' && !showGenerateBulkHeader) ? <ActionButtons isFrozen={getOttoV2Project?.isFrozen}>
                        <FreezeWrapper placement='topRight'>
                          <Button
                            className='headerBtn'
                            style={{marginRight: 10, backgroundColor: 'rgba(127, 78, 173, 0.15)', color: '#7F4EAD'}}
                            onClick={() => setShowGenerateBulkHeader(true)}
                            disabled={!disableDeployButton.length}
                          >
                            <FontAwesomeIcon icon={faListTree} color='#7F4EAD'/>Generate Articles in Bulk
                          </Button>
                        </FreezeWrapper>
                      </ActionButtons>: <></>}
                      {headerButtons[issueObject?.group] && <ActionButtons isFrozen={getOttoV2Project?.isFrozen}>
                        <FreezeWrapper placement='topRight'>
                          <Button style={headerButtons[issueObject?.group]?.style} className='headerBtn' buttonType='blue-button' onClick={headerButtons[issueObject?.group]?.fn}><FontAwesomeIcon icon={headerButtons[issueObject?.group]?.icon} color='#fff'/>{headerButtons[issueObject?.group]?.text}</Button>
                        </FreezeWrapper>
                      </ActionButtons>}
                      {issueObject?.group === 'aggregator_network' && <ActionButtons btnType='newSubmission' isFrozen={getOttoV2Project?.isFrozen}>
                        <FreezeWrapper placement='topRight'>
                          <Tooltip title={trialExpiresAtDateCheck ? '' : 'Access to this feature is restricted for free trial users.'}>
                            <div style={{width: 'fit-content'}}>
                              <Button disabled={!trialExpiresAtDateCheck} className='headerBtn' buttonType='blue-button' onClick={() => setOpenBusinessInfoModal(true)}><FontAwesomeIcon icon={faPlus} color='#fff'/>New Submission</Button>
                            </div>
                          </Tooltip>
                        </FreezeWrapper>
                      </ActionButtons>}
                      {issueObject?.group === 'gbp_posts' ? (
                        <ActionButtons isFrozen={getOttoV2Project?.isFrozen}>
                          <FreezeWrapper placement='topRight'>
                            <ButtonStyled className={isLinkgraph ?'gbp-bulk-button': 'headerBtn'} background='#2D6CCA' onClick={() => setOpenGenerateGbpPostsModal(true)}>
                              <FontAwesomeIcon icon={faPlus} fontSize={14} color='#fff'/>{showAddButtonLabel[issueObject?.group]}
                            </ButtonStyled>
                          </FreezeWrapper>
                        </ActionButtons>) : (
                        <></>
                      )}
                      {isLinkgraph && issueObject?.group === 'gbp_posts' ? (
                        <ActionButtons isFrozen={getOttoV2Project?.isFrozen}>
                          <DeployContainer className='headerBtn' marginTop={'0px'} style={{padding: '0px'}}>
                            <div className='buttons-container' style={{width: '100%'}}>
                              <FreezeWrapper>
                                <div className='button-container' style={{background: gbpAutomatedPostSettings?.isActive ? '#18923B26' : '#F4434326'}}>
                                  <div onClick={() => updateAutomatedPostSettingStatus(!gbpAutomatedPostSettings?.isActive)}>
                                    <StyledSwitch lockOn={true} checked={gbpAutomatedPostSettings?.isActive} loading={automatedGbpPostSettingLoader}/>
                                    <span>Automated Posting: {gbpAutomatedPostSettings?.isActive ? 'On' : 'Off'}</span>
                                  </div>
                                  <CommonDivider type={dividerType.VERTICAL} height='18px' color='#C1C1C1' style={{top: '0px'}}/>
                                  <FontAwesomeIcon icon={faGear} color='#4E5156' fontSize={14} style={{marginLeft: '4px'}} onClick={() => setOpenGBPAutomationModal(true)}/>
                                  <LargeBadge alpha />
                                </div>
                              </FreezeWrapper>
                            </div>
                          </DeployContainer>
                        </ActionButtons>) : (
                        <></>
                      )}

                      {issueObject?.group === 'gsc_instant_indexing' ? <AddServiceAccountButton loading={activatingIndexing} onClick={async () => {
                        setActivatingIndexing(true);
                        const res = await activateIndexing(uuid);
                        setEmail(res);
                        setActivatingIndexing(false);
                        setShowAddServiceAccount(true);
                      }}>
                        <FontAwesomeIcon icon={faPlus} color='#fff'/> Add Service Account
                      </AddServiceAccountButton> : ''}

                      {issueObject?.group === 'templates' && <ActionButtons isFrozen={getOttoV2Project?.isFrozen}>
                        <FreezeWrapper placement='topRight'>
                          <Button className='headerBtn' buttonType='blue-button' onClick={() => setCreateTempModalVisible(true)}><FontAwesomeIcon icon={faPlus} color='#fff'/>Create Template</Button>
                        </FreezeWrapper>
                      </ActionButtons>}
                      {issueObject?.group === 'campaigns' && <div style={{display: 'flex'}}>
                        <ActionButtons isFrozen={getOttoV2Project?.isFrozen} style={{position: 'absolute', right: 0, top: -2}}>
                          <FreezeWrapper placement='topRight'>
                            <Button style={{marginRight: 10, backgroundColor: 'rgba(78, 81, 86, 0.1)', color: '#4E5156'}} onClick={() => setBlackListModalVisible(true)}><FontAwesomeIcon icon={faBan} color='#4E5156'/>Global Blocklist</Button>
                          </FreezeWrapper>
                          <FreezeWrapper placement='topRight'>
                            <Button style={{marginRight: 10, backgroundColor: 'rgba(78, 81, 86, 0.1)', color: '#4E5156'}} onClick={() => setFoldersModalVisible(true)}><FontAwesomeIcon icon={faGear} color='#4E5156'/>Manage Folders</Button>
                          </FreezeWrapper>
                          <FreezeWrapper placement='topRight'>
                            <Button style={{marginRight: 10}} buttonType='blue-button' onClick={() => setCreateModalVisible(true)}><FontAwesomeIcon icon={faPlus} color='#fff'/>Create Campaign</Button>
                          </FreezeWrapper>
                        </ActionButtons>
                      </div>}
                      <QuotaContainer>
                        {quotas[issueObject?.group]?.map((item, index) => (
                          <div key={index}>
                            <Tooltip
                              overlayStyle={{maxWidth: 300}}
                              overlayInnerStyle={{background: '#000', borderRadius: 8, width: 300}}
                              title={getHyperDriveTooltip(
                                [
                                  {used: item?.recurringConsumedCount, total: item?.recurringTotalCount, label: 'Recurring'},
                                  {used: item?.nonRecurringConsumedCount, total: item?.nonRecurringTotalCount, label: 'Non-Recurring'},
                                ],
                              )}
                            >
                              <div><QuotaInfoDetail {...item} /></div>
                            </Tooltip>
                          </div>
                        ))}
                      </QuotaContainer>
                    </StyledCol>
                    <Col span={20}>
                      {selectedCategory != 'profile_optimizations' && (!gbpList.includes(issueObject?.group) && !hideDeployButtons.includes(issueObject?.group)) && <div style={{display: 'flex', gap: '10px'}}>
                        <FreezeWrapper>
                          <Tooltip title={renderTooltip} placement='top' overlayStyle={{maxWidth: '350px'}} overlayInnerStyle={{borderRadius: '8px', backgroundColor: 'black'}}>
                            <ActionButtons isFrozen={getOttoV2Project?.isFrozen} style={{cursor: (!disableDeployButton.length) && 'not-allowed'}}>
                              <Button disabled={!disableDeployButton.length} buttonType={'green'} onClick={()=>deployOttoCategory(true, selectedCategory)}>
                                <FontAwesomeIcon icon={faCheckCircle} color='#fff'/>{currentProject?.pixelTagState == 'installed' && currentProject?.isEngaged ? 'Deploy all in Category' : 'Enable all in Category'}
                              </Button>
                            </ActionButtons>
                          </Tooltip>
                        </FreezeWrapper>
                        <FreezeWrapper>
                          <ActionButtons isFrozen={getOttoV2Project?.isFrozen} style={{cursor: (!disableDeployButton.length) && 'not-allowed'}}>
                            <Button disabled={!disableDeployButton.length} buttonType='transparent-with-border' className='button-style' color='black' onClick={()=>deployOttoCategory(false, selectedCategory)}>
                              <FontAwesomeIcon icon={faHistory} color='black'/>Roll back all
                            </Button>
                          </ActionButtons>
                        </FreezeWrapper>
                      </div>
                      }
                    </Col>
                    {['meta_keywords', 'missing_headings', 'missing_keywords', 'internal_links'].includes(selectedCategory) ? <Col span={4} style={{display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', color: '#4E5156', fontSize: 12, cursor: 'pointer'}}>
                      <Tooltip placement='bottomLeft' overlayInnerStyle={{backgroundColor: '#121212', borderRadius: '8px', border: '1px solid #121212', width: 310}} title={<div style={{fontSize: 12, width: '100%'}}>
                        <p>We inject keywords, headings, links, and other elements directly into your page. These elements are hidden by default to preserve your page layout, as OTTO can’t read your page’s styling.</p>
                        <p><span style={{fontWeight: 'bold'}}>The SEO benefits are effective and almost instant,</span> even for hidden elements. You can always overwrite the CSS to blend them with your site’s design.</p>
                      </div>}>
                        <FontAwesomeIcon icon={faCircleInfo}/> How it works
                      </Tooltip>
                    </Col>: null}
                  </Row>
                </FilterWrapper>
                {selectedCategory && <DetailWrapper className={selectedCategory} removeCssProperties={selectedCategory === 'gsc_instant_indexing'}>
                  <TableIssuesCollapse
                    campaignsId={campaignsId}
                    setCampaignsId={setCampaignsId}
                    openGenerateGbpPostsModal={openGenerateGbpPostsModal}
                    setOpenGenerateGbpPostsModal={setOpenGenerateGbpPostsModal}
                    showGenerateBulkHeader={showGenerateBulkHeader}
                    setShowGenerateBulkHeader={setShowGenerateBulkHeader}
                  />
                </DetailWrapper>}
              </>
            }
          </Col>
        </Row>
      </WhiteSectionWrapper>
      <TopupModal topUpBulkVisibility={topUpBulkVisibility} setTopUpBulkVisibility={setTopUpBulkVisibility} />
      {
        openAutoRespondModal && <AutoRespondModal gbpLocationDetail={gbpLocationDetail} openModal={openAutoRespondModal} setOpenModal={setOpenAutoRespondModal}/>
      }
      {openBusinessInfoModal && <BusinessInfoModal
        visible={openBusinessInfoModal}
        setVisible={setOpenBusinessInfoModal}
        setOpenCompletionModal={setOpenCompletionModal}
        setSubmittedBusinessData={setSubmittedBusinessData}
      />}
      {openCompletionModal && <CompletionModal
        visible={openCompletionModal}
        setVisible={setOpenCompletionModal}
        submittedBusinessData={submittedBusinessData}
        setSubmittedBusinessData={setSubmittedBusinessData}
      />}
      <AddGscServiceAccModal visible={showAddServiceAccount} setVisible={setShowAddServiceAccount} email={email} />
      {blackListModalVisible && <BlockListModal
        blackListModalVisible={blackListModalVisible}
        setBlackListModalVisible={setBlackListModalVisible}
      />}
      {!isEditModal && <CreateCampaignModal isInOTTO={true}/>}
      {
        openLandingPageModal && <LandingPageGeneratorModal openModal={openLandingPageModal} setOpenModal={setOpenLandingPageModal}/>
      }
      <ViewCitationModal openCitationModal={openCitationModal} setOpenCitationModal={setOpenCitationModal} openedBusinessInModal={openCompletionModal.data} />
      {
        openGBPAutomationModal && <GBPAutomationModal openModal={openGBPAutomationModal} setOpenModal={setOpenGBPAutomationModal} setOpenGbpLibraryModal={setOpenGbpLibraryModal}/>
      }
      {
        openGbpLibraryModal && <GBPMediaLibraryModal
          openGbpLibraryModal={openGbpLibraryModal}
          setOpenGbpLibraryModal={setOpenGbpLibraryModal}
          setOpenGBPAutomationModal={setOpenGBPAutomationModal}
          // editing={editingPost}
          // setEditing={setEditingPost}
          // showConfirmationPopup={showConfirmationPopup}
          // deleteItem={deleteItem}
        />
      }
    </>
  );
});

const SectionHeading = styled.div`
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  font-size: 18px;
  color: #121212;
  margin-bottom: 20px;
`;

const TooltipWrapper = styled.div`
  .time-saved-table {
      width: 100%;
      .table-head {
        .table-row {
          .text-left {
            text-align: left;
          }
          .text-right {
            text-align: right;
          }
          .title {
            font-family: 'Inter', sans-serif;
            font-size: 13px;
            font-weight: 700;
            color: #fff;
          }
        }
      }
      .table-body {
        .table-row {
          .sans-serif {
            font-family: 'Inter', sans-serif;
          }
          .font-14 {
            font-size: 14px;
          }
          .font-600 {
            font-weight: 600;
          }
          .font-400 {
            font-weight: 400;
          }
          .color-white {
            color: #fff;
          }
          .text-right {
            text-align: right;
          }
          .inner-text {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 100%;
            width: fit-content;
          }
        }
      }
    }
`;

const DropdownWrapper = styled.div`
  padding: 0 8px;
  margin-left: 8px;
  font-size: 14px;
  border: 1px solid #E8E8E8;
  border-radius: 8px;
  color: #4E5156;
  display: flex;
  align-items: center;  
  gap: 9px;
  cursor: pointer;
  background: #fff;
`;
const StyledCol = styled(Col)`
  @media screen and (max-width: 1100px) {
    flex-wrap: wrap;
    gap: 12px;
    row-gap: 8px;
    margin-bottom: 10px;
    .issue-count {
      padding-left: 0 !important;
    }
  }
`;
const Header = styled.div`
  .recommended-text {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #fff;
    padding-left: 10px;
  }
  .pages-count {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: #e8e8e8;
  }
`;
const WhiteSectionWrapper = styled.div`
  border-radius: 12px;
  border: 1px solid #E8E8E8;
  box-shadow: 0px 3px 2px 0px #00000005;
  padding: 25px;
  background-color: #F9F9FB;
`;

const FilterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ActionButtons = styled.div<{isFrozen?: boolean; btnType?: string}>`
  display: flex;
  align-items: center;
  ${p => p?.isFrozen ? `
    justify-content: flex-end;
    width: ${p?.btnType == 'newSubmission' ? 'fit-content' : '100%'};  
  ` : ''}

  button {
    padding: 5px 15px 5px 15px;
    font-size: 14px;
    span {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
  .headerBtn{
    ${p => !p.isFrozen && p?.btnType !== 'newSubmission' ? `
      position: absolute;
      right: 0;
    ` : ''}
    .buttons-container {
      .button-container {
        display: flex;
        align-items: center;
        padding: 10px 15px;
      }
      .ant-switch {
        .ant-switch-handle {
          &::before {
            top: 1px !important;
            bottom: 0px !important;
          }
        }
      }
    }
  }
  .gbp-bulk-button {
    ${p => !p.isFrozen ? `
      position: absolute;
      right: 326px;
    ` : ''}
  }
  .button-style {
    padding: 5px 15px 5px 15px;
    font-size: 14px;
    color: black;
    
    :hover {
      background-color: #eeeeee;
    }

    span {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }
  .disabled {
    cursor: not-allowed !important;
  }
`;
const DetailWrapper = styled.div<{removeCssProperties?: boolean}>`
  ${p => p?.removeCssProperties ? '' : `
    box-shadow: 0px 3px 2px 0px #00000005;
    border: 1px solid #E8E8E8;
    background: #FFFFFF;
    padding: 20px 10px 10px 10px;
    border-radius: 12px;
  `}
  margin-top: 14px;
  border-radius: 12px;
  &.aggregator_network {
    padding: 0px 10px 10px 10px;
  }
  &.campaigns {
    padding: 0;
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
  &.templates {
    padding: 0;
    background-color: transparent;
    border: none;
    box-shadow: none;
  }
`;
const IssueTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0px 15px;
`;
const IssueCount = styled.div`
  line-height: normal;
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #4E5156;
`;
const StyledTabs = styled(Tabs)`
  padding-left: 10px;
  .rc-tabs > .rc-tabs-nav {
    height: auto !important;
    margin-top: 0px !important;
    background-color: transparent;
    .rc-tabs-nav-operations {
      background-color: #1F2125 !important;
      height: fit-content !important;
      .rc-tabs-nav-more {
        background-color: #1F2125 !important;
        font-size: 20px;
        height: 0px;
        margin-top: 5px;
      }
    }
  }
  .rc-tabs > .rc-tabs-nav > .rc-tabs-nav-wrap > .rc-tabs-nav-list {
    .rc-tabs-tab {
      .rc-tabs-tab-btn {
        padding: 10px 0px !important;
      }
    }
  }
`;
const ComingSoonListWrapper = styled.ul`
  border-radius: 12px;
  border: 1px solid #E8E8E8;
  list-style: none;
  padding: 16px 20px;
  margin-bottom: 0px;
`;
const StyledComingSoonItem = styled.li`
  display: flex;
  align-items: flex-start;
  gap: 15px;
  padding: 9px 0px;
  box-shadow: 0px 1px 0px 0px #E7E7E7;
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: 43px;
    height: 43px;
    background-color: rgba(255, 133, 54, 15%);
    border-radius: 8px;
    svg {
      font-size: 24px;
      width: 26px;
      height: 26px;
    }
  }
  .content {
    width: 1066px;
    .title {
      font-family: 'Inter', sans-serif;
      font-size: 15px;
      font-weight: 500;
      color: #121212;
      line-height: 18.15px;
    }
    .description {
      max-width: 66%;
      font-family: 'Inter', sans-serif;
      font-size: 13px;
      font-weight: 400;
      color: #4E5156;
      line-height: 15.73px;
    }
  }
  .tag-wrapper {
    width: 190px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .tag {
      width: 102px;
      background-color: #F2F2F5 !important;
      color: #4E5156;
      font-family: 'Inter', sans-serif;
      font-size: 12px;
      font-weight: 400;
    }
  }
`;

const QuotaContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 22px;
  margin-left: auto;
  white-space: nowrap;
`;

export const DropdownContainer = styled.div`
  width: 400px;
  border-radius: 12px;
  background: white;
  padding: 14px 12px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`;

const DropdownListContainer = styled.div`
  max-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #4E515680;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #F9F9FB;
    border-radius: 10px;
  }
`;

const GbpSearchInput = styled(Input)`
  width: 380px;
  height: 32px;
  margin-top: 9px;
  margin-bottom: 10px;
  border-radius: 8px;
  border: 1px solid #CDCDCD;
`;

