export const routes = {
  home: '/home',
  tutorials: 'tutorials',
  ottopage: 'otto-page',
  ottopagePublic: 'otto-page-public',
  ottoPageV2: 'otto-page-v2',
  ottoPageV2Public: 'otto-page-v2-public',
  login: 'login',
  logout: 'logout',
  reportBuilder: {
    history: 'report-builder',
    bulkExport: 'report-builder/full-report',
    detail: 'report-builder/detail',
    list: 'report-builder/projects-list',
  },
  // reportBuilderV2: {
  //   history: 'report-builder-v2',
  //   bulkExport: 'report-builder-v2/full-report',
  //   detail: 'report-builder-v2/detail',
  //   list: 'report-builder-v2/projects-list',
  // },
  keywordExplorer: {
    default: 'research/keyword-researcher-old',
    listing: 'research/keyword-researcher-old/list',
    detail: 'research/keyword-researcher-old/detail',
  },
  keywordExplorerNew: {
    default: 'research/keyword-researcher',
    details: 'research/keyword-researcher/new/',
  },
  competitorExplorer: {
    default: 'research/competitor-researcher',
    public: 'research/competitor-researcher-public',
    listing: 'research/competitor-researcher/list',
    detail: 'research/competitor-researcher/detail',
  },
  competitorResearcher: {
    default: 'site-explorer/list',
    defaultPublic: 'site-explorer/list-public',
    detail: 'site-explorer/detail',
    detailPublic: 'site-explorer/detail-public',
    pages: 'site-explorer/pages',
    pagesPublic: 'site-explorer/pages-public',
    keywords: 'site-explorer/keywords',
    keywordsPublic: 'site-explorer/keywords-public',
    subdomains: 'site-explorer/subdomains',
    subdomainsPublic: 'site-explorer/subdomains-public',
    backlinks: 'site-explorer/backlinks',
    backlinksPublic: 'site-explorer/backlinks-public',
    googlePage: 'site-explorer/google-page',
    googlePagePublic: 'site-explorer/google-page-public',
    positionChanges: 'site-explorer/position-changes',
    positionChangesPublic: 'site-explorer/position-changes-public',
    competitors: 'site-explorer/competitors',
    competitorsPublic: 'site-explorer/competitors-public',
    // for home v2
    siteHealth: 'site-audit/overview',
    siteAudit: 'site-audit/list',
    trackedKeywords: 'research/keyword-rank-tracker',
    AISEOStrategy: '/otto-page',
    facebookAds: '/site-explorer/facebook-ads',
    facebookAdsPublic: '/site-explorer/facebook-ads-public',
  },
  backlinkResearcher: '/research/backlink-researcher',
  backlinkResearcherPublic: '/research/backlink-researcher-public',
  backlinkProfileAnalysis: '/research/backlink-profile-analysis',
  backlinkGapAnalysis: '/research/backlink-gap-analysis',
  keywordGapAnalysis: '/research/keyword-gap-analysis',
  keywordRankTracker: '/research/keyword-rank-tracker',
  keywordRankTrackerPublic: '/research/keyword-rank-tracker-public',
  localSeo: '/research/local-seo',
  localSeoProjectsList: '/research/seo-projects-list',
  gbpAutomations: '/research/gbp-automations/posts',
  gbpAutomationsPosts: '/research/gbp-automations/posts',
  gbpAutomationsReviews: '/research/gbp-automations/reviews',
  gbpAutomationsQuestions: '/research/gbp-automations/questions-and-answers',
  aiGmbWriter: '/research/ai-gmb-writer',
  localCitations: '/research/local-citations',
  localSeoPublic: '/research/local-seo-public',
  // secondary navbar routes
  internalPagesReport: '/site-audit-old/reports/internal-pages-report',
  indexabilityReport: '/site-audit-old/reports/indexability-report',
  linksReport: '/site-audit-old/reports/links-report',
  redirectsBrokenReport: '/site-audit-old/reports/redirects-broken-report',
  xmlSitemapsReport: '/site-audit-old/reports/xml-sitemaps-report',
  pageSpeedReport: '/site-audit-old/reports/page-speed-report',
  contentDuplicatesReport: '/site-audit-old/reports/content-duplicate-report',
  socialTagsReport: '/site-audit-old/reports/social-tags-report',
  externalPagesReport: '/site-audit-old/reports/external-pages-report',
  localizationReport: '/site-audit-old/reports/localization-report',

  gsc: 'gsc',
  overview: 'gsc/overview',
  topPages: 'gsc/top-pages',
  topPagesNew: 'gsc/pages',
  contentResearcher: '/research/content-researcher',
  topKeywords: 'gsc/top-keywords',
  keywords: 'gsc/keywords',
  backlinks: 'gsc/backlinks',
  competitorDiscovery: 'gsc/competitor-discovery',
  siteStructure: 'gsc/site-structure',
  siteAuditor: 'site-audit',
  landingPageOptimizer: 'content/seo-content-assistant',
  landingPageOptimizerPublic: 'content/seo-content-assistant-public',
  contentIdeas: 'content/metatags-generator',
  onPageAudit: 'content/onpage-audit',
  onPageAuditPublic: 'content/onpage-audit-public',
  contentPlanner: 'content/content-planner',
  onPageAuditDetails: 'content/onpage-audit/detail',
  onPageAuditDetailsPublic: 'content/onpage-audit/detail-public',
  aiContentGenerator: 'content/ai-content-generator',
  aiContentGeneratorPublic: 'content/ai-content-generator-public',
  createTasks: 'content/tasks',
  focusTerms: 'focus-terms',
  topicalMaps: 'content/topical-maps',
  bulkDaChecker: 'bulk-da-checker',
  backlinkAnalyzer: 'backlink/backlink-analyzer',
  topicalRelevance: 'backlink/topical-relevance',
  anchorText: 'backlink/anchor-text',
  toxicBacklink: 'backlink/toxic-backlinks',
  backlinkVelocity: 'backlink/backlink-velocity',

  localSearchResults: 'local-search-results',
  schema: 'schema',
  settings: 'settings',
  account: {
    default: 'account',
    resendConfirmationEmail: 'account/resend-confirmation-email',
    signup: 'account/signup',
    resetPassword: 'account/reset-password',
    team: 'account/team',
  },
  scheduleConsult: 'schedule-consult',
  orders: {
    default: 'orders',
    linkBuilding: 'orders/link-building',
  },
  orderBuilder: 'order-builder',
  checkout: 'checkout',
  subscription: 'subscription',
  planDowngrade: 'plan-downgrade',
  integration: 'integrations/oauth2',
  performance: {
    default: 'performance',
    desktop: 'performance/desktop',
    mobile: 'performance/mobile',
  },
  domainAnalyzer: 'analyze?website=',
  meta: {
    default: 'meta',
    title: 'meta/title',
    header: 'meta/header',
    description: 'meta/description',
  },
  pageExplorer: '/site-audit/page-explorer',
  pricing: 'pricing',
  billing: 'billing',
  appsEcoSystem: 'app-ecosystem',

  test: 'test',
  schemaForm: 'schema-form',
  startOnBoarding: 'onboarding',
  agencySettings: 'agency-settings',
  saIssuesPageOld: '/site-audit/issues-page-old',
  saIssuesPage: '/site-audit/issues-page',
  saIssuesPagePublic: '/site-audit/issues-page-public',
  saAlertsPage: '/site-audit/alerts-page',
  saAlertsPagePublic: '/site-audit/alerts-page-public',
  haro: 'haro',
  digitalPr: 'digital-pr/pr-outreach',
  journalistDetails: 'digital-pr/journalist-details',
  mediaOutletsDetail: 'digital-pr/mediaOutlets',
};

export const publicPages = [
  '/login',
  '/register',
  '/register-b',
  '/verify-customer/[...code]',
  '/forgot-password',
  '/resend-verification',
  '/analyze',
  '/bulk-da-checker-public',
  '/research/keyword-rank-tracker-public',
  '/research/local-seo-public',
  '/research/local-seo-public/[...id]',
  '/research/local-seo-public/[...id]',
  '/research/keyword-researcher-public',
  '/research/keyword-researcher-public/[...keyword]',
  '/research/keyword-researcher-old-public/[...keyword]',
  '/research/keyword-magic-tool-public',
  '/research/keyword-magic-tool-public/[...keyword]',
  '/research/keyword-researcher-magic-public/[...keyword]',
  '/content/seo-content-assistant-public/[uuid]',
  '/content/onpage-audit/detail-public/[...auditId]',
  '/content/onpage-audit/public',
  '/content/onpage-audit-public',
  '/content/content-planner/public/[...id]',
  '/content/content-planner-public',
  '/content/content-planner/public',
  '/content/ai-content-generator-public',
  '/content/rewriting-tool-public',
  '/content/content-ideas/public',
  '/content/metatags-generator/public',
  '/order-builder',
  '/otto-page-public',
  '/otto-page-public/tasks',
  '/pricing',
  '/site-audit/overview-public',
  '/gsc/overview-public',
  '/site-audit/issues-page-public',
  '/site-audit/issues-page-old-public',
  '/site-audit/sitewide-data-public',
  '/site-audit/page-explorer-public',
  '/site-audit/page-explorer-public/[...id]',
  '/research/backlink-researcher-public',
  '/research/backlink-researcher-public/[...keyword]',
  '/research/backlink-researcher-public',
  '/research/competitor-researcher-public/[...keyword]',
  '/research/competitor-researcher-public',
  '/research/keyword-researcher-old/public',
  '/research/domain-analyzer-public',
  '/research/backlink-profile-analysis-public',
  '/research/backlink-profile-analysis-public/[...id]',
  '/test-da',
  '/go/url',
  '/site-audit/list-public',
  '/site-audit/alerts-page-public',
  '/site-explorer/detail-public/[...id]',
  '/site-explorer/detail-public',
  '/site-explorer/list-public',
  '/site-explorer/keywords-public/[...id]',
  '/site-explorer/keywords-public',
  '/site-explorer/competitors-public/[...id]',
  '/site-explorer/competitors-public',
  '/site-explorer/backlinks-public/[...id]',
  '/site-explorer/backlinks-public',
  '/site-explorer/pages-public/[...id]',
  '/site-explorer/pages-public',
  '/site-explorer/position-changes-public/[...id]',
  '/site-explorer/position-changes-public',
  '/site-explorer/subdomains-public/[...id]',
  '/site-explorer/subdomains-public',
  '/site-explorer/google-page-public/[...id]',
  '/site-explorer/google-page-public',
  '/report-builder/full-report-public',
  '/report-builder/full-report-public',
  '/otto-page-v2/public',
  '/research/keyword-rank-tracker-public/[...id]',
  '/site-explorer/facebook-ads-public/[...id]',
  '/go/view-report-public',
];

export const linkgraphDomain = 'linkgraph.com';


// we must make sure to put each chart into group
// this will help show multiple charts on single page after they are hidden
// we can hide only some charts, but will always show them all
export const chartGroups = {
  pages: {
    PAGES_PAGE_CHART: 'PAGES_PAGE_CHART',
  },
};

export const fileErrors = {
  'Error opening GoogleSheets file.': 'Please check your file is valid and public.',
  'File is empty.': 'File or first line is empty, please try again.',
};

export const orderTypes = {
  customer_plan: 'Subscription',
  premium_ai_quota_top_up: 'Full Article Generation Quota Top Up',
  ai_quota_top_up: 'AI Quota Top Up',
  order: 'Order',
};

export const countryCodeToLocationDict = {
  'AF': 'Afghanistan',
  'AL': 'Albania',
  'DZ': 'Algeria',
  'AS': 'American Samoa',
  'AD': 'Andorra',
  'AO': 'Angola',
  'AI': 'Anguilla',
  'AQ': 'Antarctica',
  'AG': 'Antigua and Barbuda',
  'AR': 'Argentina',
  'AM': 'Armenia',
  'AW': 'Aruba',
  'AU': 'Australia',
  'AT': 'Austria',
  'AZ': 'Azerbaijan',
  'BS': 'Bahamas',
  'BH': 'Bahrain',
  'BD': 'Bangladesh',
  'BB': 'Barbados',
  'BY': 'Belarus',
  'BE': 'Belgium',
  'BZ': 'Belize',
  'BJ': 'Benin',
  'BM': 'Bermuda',
  'BT': 'Bhutan',
  'BO': 'Bolivia',
  'BQ': 'Bonaire, Sint Eustatius and Saba',
  'BA': 'Bosnia and Herzegovina',
  'BW': 'Botswana',
  'BV': 'Bouvet Island',
  'BR': 'Brazil',
  'IO': 'British Indian Ocean Territory',
  'BN': 'Brunei Darussalam',
  'BG': 'Bulgaria',
  'BF': 'Burkina Faso',
  'BI': 'Burundi',
  'KH': 'Cambodia',
  'CM': 'Cameroon',
  'CA': 'Canada',
  'CV': 'Cape Verde',
  'KY': 'Cayman Islands',
  'CF': 'Central African Republic',
  'TD': 'Chad',
  'CL': 'Chile',
  'CN': 'China',
  'CX': 'Christmas Island',
  'CC': 'Cocos (Keeling) Islands',
  'CO': 'Colombia',
  'KM': 'Comoros',
  'CG': 'Congo',
  'CD': 'Congo, Democratic Republic',
  'CK': 'Cook Islands',
  'CR': 'Costa Rica',
  'CI': "Cote d'Ivoire",
  'HR': 'Croatia',
  'CU': 'Cuba',
  'CW': 'CuraÃ§ao',
  'CY': 'Cyprus',
  'CZ': 'Czech Republic',
  'DK': 'Denmark',
  'DJ': 'Djibouti',
  'DM': 'Dominica',
  'DO': 'Dominican Republic',
  'TL': 'East Timor',
  'EC': 'Ecuador',
  'EG': 'Egypt',
  'SV': 'El Salvador',
  'GQ': 'Equatorial Guinea',
  'ER': 'Eritrea',
  'EE': 'Estonia',
  'ET': 'Ethiopia',
  'FK': 'Falkland Islands (Islas Malvinas)',
  'FO': 'Faroe Islands',
  'FJ': 'Fiji',
  'FI': 'Finland',
  'FR': 'France',
  'GF': 'French Guiana',
  'PF': 'French Polynesia',
  'TF': 'French Southern Territories',
  'GA': 'Gabon',
  'GM': 'Gambia',
  'GE': 'Georgia',
  'DE': 'Germany',
  'GH': 'Ghana',
  'GI': 'Gibraltar',
  'GR': 'Greece',
  'GL': 'Greenland',
  'GD': 'Grenada',
  'GP': 'Guadeloupe',
  'GU': 'Guam',
  'GT': 'Guatemala',
  'GG': 'Guernsey',
  'GN': 'Guinea',
  'GW': 'Guinea-Bissau',
  'GY': 'Guyana',
  'HT': 'Haiti',
  'HM': 'Heard and McDonald Islands',
  'HN': 'Honduras',
  'HK': 'Hong Kong',
  'HU': 'Hungary',
  'IS': 'Iceland',
  'IN': 'India',
  'ID': 'Indonesia',
  'IR': 'Iran',
  'IQ': 'Iraq',
  'IE': 'Ireland',
  'IL': 'Israel',
  'IT': 'Italy',
  'JM': 'Jamaica',
  'JP': 'Japan',
  'JE': 'Jersey',
  'JO': 'Jordan',
  'KZ': 'Kazakhstan',
  'KE': 'Kenya',
  'KI': 'Kiribati',
  'KW': 'Kuwait',
  'KG': 'Kyrgyzstan',
  'LA': "Lao People's Democratic Republic",
  'LV': 'Latvia',
  'LB': 'Lebanon',
  'LS': 'Lesotho',
  'LR': 'Liberia',
  'LY': 'Libya',
  'LI': 'Liechtenstein',
  'LT': 'Lithuania',
  'LU': 'Luxembourg',
  'MO': 'Macau',
  'MK': 'Macedonia',
  'MG': 'Madagascar',
  'MW': 'Malawi',
  'MY': 'Malaysia',
  'MV': 'Maldives',
  'ML': 'Mali',
  'MT': 'Malta',
  'MH': 'Marshall Islands',
  'MQ': 'Martinique',
  'MR': 'Mauritania',
  'MU': 'Mauritius',
  'YT': 'Mayotte',
  'MX': 'Mexico',
  'FM': 'Micronesia',
  'MD': 'Moldova',
  'MC': 'Monaco',
  'MN': 'Mongolia',
  'ME': 'Montenegro',
  'MS': 'Montserrat',
  'MA': 'Morocco',
  'MZ': 'Mozambique',
  'MM': 'Myanmar',
  'NA': 'Namibia',
  'NR': 'Nauru',
  'NP': 'Nepal',
  'NL': 'Netherlands',
  'AN': 'Netherlands Antilles',
  'NC': 'New Caledonia',
  'NZ': 'New Zealand',
  'NI': 'Nicaragua',
  'NE': 'Niger',
  'NG': 'Nigeria',
  'NU': 'Niue',
  'NF': 'Norfolk Island',
  'KP': 'North Korea',
  'MP': 'Northern Mariana Islands',
  'NO': 'Norway',
  'OM': 'Oman',
  'PK': 'Pakistan',
  'PW': 'Palau',
  'PS': 'Palestinian Territory',
  'PA': 'Panama',
  'PG': 'Papua New Guinea',
  'PY': 'Paraguay',
  'PE': 'Peru',
  'PH': 'Philippines',
  'PN': 'Pitcairn',
  'PL': 'Poland',
  'PT': 'Portugal',
  'PR': 'Puerto Rico',
  'QA': 'Qatar',
  'RE': 'Reunion',
  'RO': 'Romania',
  'RU': 'Russia',
  'RW': 'Rwanda',
  'KN': 'Saint Kitts and Nevis',
  'LC': 'Saint Lucia',
  'VC': 'Saint Vincent and the Grenadines',
  'WS': 'Samoa',
  'SM': 'San Marino',
  'ST': 'Sao Tome and Principe',
  'SA': 'Saudi Arabia',
  'SN': 'Senegal',
  'RS': 'Serbia',
  'SC': 'Seychelles',
  'SL': 'Sierra Leone',
  'SG': 'Singapore',
  'SX': 'Sint Maarten (Dutch part)',
  'SK': 'Slovakia',
  'SI': 'Slovenia',
  'SB': 'Solomon Islands',
  'SO': 'Somalia',
  'ZA': 'South Africa',
  'GS': 'South Georgia and The South Sandwich Islands',
  'KR': 'Korea Republic',
  'ES': 'Spain',
  'LK': 'Sri Lanka',
  'SH': 'St. Helena',
  'PM': 'St. Pierre and Miquelon',
  'SD': 'Sudan',
  'SR': 'Suriname',
  'SJ': 'Svalbard and Jan Mayen Islands',
  'SZ': 'Swaziland',
  'SE': 'Sweden',
  'CH': 'Switzerland',
  'SY': 'Syrian Arab Republic',
  'TW': 'Taiwan',
  'TJ': 'Tajikistan',
  'TZ': 'Tanzania',
  'TH': 'Thailand',
  'TG': 'Togo',
  'TK': 'Tokelau',
  'TO': 'Tonga',
  'TT': 'Trinidad and Tobago',
  'TN': 'Tunisia',
  'TR': 'Turkey',
  'TM': 'Turkmenistan',
  'TC': 'Turks and Caicos Islands',
  'TV': 'Tuvalu',
  'UG': 'Uganda',
  'UA': 'Ukraine',
  'AE': 'United Arab Emirates',
  'GB': 'United Kingdom',
  'US': 'United States',
  'UM': 'United States Minor Outlying Islands',
  'UY': 'Uruguay',
  'UZ': 'Uzbekistan',
  'VU': 'Vanuatu',
  'VA': 'Vatican',
  'VE': 'Venezuela',
  'VN': 'Vietnam',
  'VG': 'Virgin Islands (British)',
  'VI': 'Virgin Islands (U.S.)',
  'WF': 'Wallis and Futuna Islands',
  'EH': 'Western Sahara',
  'YE': 'Yemen',
  'ZM': 'Zambia',
  'ZW': 'Zimbabwe',
};

export const dateRangeDict = {
  oneMonth: '1 month',
  threeMonth: '3 Months',
  sixMonth: '6 Months',
  oneYear: '1 Year',
};
